@import '../../../asset/styles/scss/common/colors';
@import '../../../asset/styles/scss/common/media-queries';

.web_output_container {
  table, th, td {
    border: 1px solid $black;
    border-collapse: collapse;
  }
  .water_mark_container {
    padding: 15px;
    &.header_water_mark {
      padding: 15px 15px 0;
    }
    .water_mark {
      background-color: rgba( $color_gray, 0.4);
      color: $blue;
      padding: 5px 20px;
      text-align: center;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      @include media_query(XL) {
        font-size: 20px;
        line-height: 29px;
      }
    }
    @include media_query(L) {
      padding: 25px 25px 0;
      &.header_water_mark {
        padding: 25px 25px 0;
      }
    }
    @include media_query(XL) {
      padding: 30 75px;
      &.header_water_mark {
        padding: 40px 80px 0;
      }
    }
    @include media_query(XXL) {
      padding: 30 75px;
      &.header_water_mark {
        padding: 68px 80px 0;
      }
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 15px;
    border-bottom: 1px solid $blue;
    cursor: pointer;
    .logo_area {
      .img_wrapper {
        width: 50px;
        @include media_query(L) {
          width: 75px;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .logo-text {
        color: $blue;
        font-weight: 600;
        font-size: 6px;
        letter-spacing: 2px;
        text-align: center;
        display: none;
        align-items: center;
        .beta-txt {
          background-color: rgba($dragon_green, 0.4);
          border-radius: 8px;
          color: $blue;
          font-size: 9px;
          line-height: 12px;
          font-weight: 400;
          letter-spacing: .5px;
          margin-left: 4px;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          height: 14px;
          width: 34px;
        }
        @include media_query(L) {
          font-size: 16px;
          display: flex;
        }
      }
    }
    .btn_area {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        background: $dragon_green;
        color: $white;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
        position: relative;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        margin-right: 10px;
        .icon {
          width: 24px;
          height: 24px;
          display: flex;
          @include media_query(L) {
            margin-right: 7px;
            align-items: center;
            justify-content: center;
          }
        }
        .lbl {
          display: none;
          @include media_query(L) {
            display: flex;
          }
        }
        &:last-child {
          margin-right: 0px;
        }
        @include media_query(L) {
          justify-content: flex-start;
          width: 183px;
          height: auto;
          border-radius: 8px;
          padding: 8px;
          margin-right: 20px;
          margin-bottom: auto;
        }
        @include media_query(XXL) {
          padding: 13px;
          font-size: 18px;
          line-height: 24px;
          width: 232px;
          margin-right: 30px;
        }
        .dropdown_lbl {
          display: none;
          @include media_query(L) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
          }
          .dropdown_icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 15px;
            height: 24px;
          }
        }
        .dropdown_area {
          position: absolute;
          top: 100%;
          left: 0;
          width: 86px;
          background-color: $white;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
          border-radius: 0 0 6px 6px;
          z-index: 2;
          .item {
            color: $blue;
            padding: 12px;
            font-weight: 600;
          }
          @include media_query(L) {
            width: 100%;
          }
        }
      }
      @include media_query(L) {
        flex-direction: row;
      }
    }
    @include media_query(L) {
      padding: 25px;
    }
    @include media_query(XL) {
      padding: 40px 75px;
    }
  }
  .blurred {
    filter: blur(5px);
  }
  .customer_details {
    padding: 20px 15px 0;
    margin-bottom: 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    border-bottom: 1px solid $blue;
    .details_area {
      border-bottom: 1px solid $blue;
      width: 100%;
      &:last-child {
        border-bottom: 0;
      }
      &:first-child {
        margin-bottom: 20px;
      }
      .detail {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: $black;
        margin-bottom: 20px;
        .lbl {
          margin-right: 8px;
        }
        .icon {
          width: 24px;
          height: 24px;
          border: 1px solid $black;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 9px;
        }
        .bold {
          font-weight: 500;
          color: $dragon_green;
          text-decoration: underline;
          word-break: break-all;
          width: 180px;
          line-height: 20px;
          @include media_query(L) {
            width: auto;
          }
        }
        @include media_query(XL) {
          font-size: 21px;
          line-height: 21px;
          &:first-child {
            margin-bottom: 40px;
          }
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
      @include media_query(L) {
        border-bottom: 0;
        border-right: 1px solid $blue;
        &:first-child {
          margin-bottom: 0px;
          margin-right: 20px;
        }
        &:last-child {
          border-right: 0;
        }
      }
      @include media_query(XL) {
        width: 50%;
        padding: 0 75px;
      }
    }
    @include media_query(L) {
      padding: 20px 15px;
      flex-direction: row;
    }
    @include media_query(XL) {
      padding: 40px 0;
    }
  }
  .section_header {
    //width: 100%;
    padding: 8px 20px; 
    margin-bottom: 24px;
    background: $blue;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: $white;
    text-transform: uppercase;
    @include media_query(L) {
      padding: 14px 20px; 
      width: 400px;
      max-width: 520px;
      margin-bottom: 34px;
    }
    @include media_query(XL) {
      width: 550px;
      max-width: 720px;
      padding: 14px 20px 14px 75px; 
      margin-bottom: 44px;
      font-size: 24px;
      line-height: 24px;
    }
  }
  .house_details {
    margin-bottom: 30px;
    .table_area {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 15px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      .custom_table {
        width: 100%;
        td {
          padding: 12px 10px;
          @include media_query(XL) {
            padding: 32px 20px;
          }
        }
      }
      @include media_query(L) {
        padding: 0 25px;
        font-size: 21px;
        line-height: 28px;
      }
      @include media_query(XL) {
        padding: 0 75px;
      }
    }
    @include media_query(XL) {
      margin-bottom: 80px;
    }
  }
  .interactive_house {
    margin-bottom: 20px;
    .title {
      display: flex;
      flex-direction: column;
      .btn_area {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        margin-bottom: 20px;
        padding-right: 15px;
        .btn {
          display: flex;
          justify-content: center;
          align-items: center;
          background: $dragon_green;
          color: $white;
          text-transform: uppercase;
          width: 36px;
          height: 36px;
          border-radius: 50%;
          font-weight: 700;
          cursor: pointer;
          margin-left: 10px;
          .icon {
            width: 24px;
            height: 24px;
            img {
              width: 100%;
              height: 100%;
            }
            @include media_query(L) {
              margin-right: 7px;
            }
          }
          .lbl {
            display: none;
            @include media_query(L) {
              display: flex;
            }
          }
          @include media-query(L) {
            justify-content: flex-start;
            border-radius: 8px;
            padding: 13px;
            width: 210px;
            height: 50px;
            margin-left: 20px;
          }
        }
        @include media_query(M) {
          flex-direction: row;
          margin-bottom: 34px;
          padding-right: 15px;
        }
        @include media_query(L) {
          align-items: center;
          padding-right: 25px;
        }
        @include media_query(XL) {
          padding-right: 75px;
          margin-bottom: 44px;
        }
      }
      @include media_query(XL) {
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
      }
    }
    .house_3d_model_area {
      margin-bottom: 20px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 15px;
      .house_3d_model_mobile {
        display: block;
        position: relative;
        height: 320px;
        width: 100%;
        @include media_query(M) {
          height: 500px;
        }
        @include media_query(L) {
          display: none;
        }
        .tap_to_touch_overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, .4);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 2;
        }
      }
      .house_3d_model {
        display:none;
        @include media_query(L) {
          width: 100%;
          height: 100%;
          display: block;
        }
        @include media_query(XL) {
          min-height: 740px;
        }
      }
      .btn_area {
        position: absolute;
        bottom: 0;
        right: 15px;
        display: flex;
        flex-direction: row;
        align-items:center;
        justify-content: space-between;
        .refresh_btn {
          width: 33px;
          height: 33px;
          margin-right: 16px;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
          @include media_query(L) {
            margin-right: 0;
            margin-bottom: 15px;
          }
          @include media_query(XL) {
            width: 63px;
            height: 63px;
            margin-bottom: 30px;
          }
        }
        .zoom_btn {
          background: $dragon_green;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
          border-radius: 2px;
          cursor: pointer;
          display: flex;
          flex-direction: row;
          .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 33px;
            height: 33px;
            cursor: pointer;
            img {
              width: 14px;
              height: 14px;
            }
            &:first-child {
              border-right: .5px solid $white ;
            }
            @include media_query(L) {
              &:first-child {
                border-right: 0;
                border-bottom: .5px solid $white ;
              }
            }
            @include media_query(XL) {
              width: 63px;
              height: 51px;
              img {
                width: 24px;
                height: 24px;
              }
            }
          }
          @include media_query(L) {
            flex-direction: column;
          }
        }
        @include media_query(L) {
          flex-direction: column;
          right: 25px;
        }
        @include media_query(XL) {
          right: 79px;
        }
      }
      @include media_query(L) {
        padding: 0 25px;
      }
      @include media_query(XL) {
        padding: 0 75px;
      }
    }
    .floor_plan_area {
      display: flex;
      flex-wrap: wrap;
      padding: 0 15px;
      .floor_plan {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .lbl {
          font-weight: 600;
          font-size: 15px;
          line-height: 20px;
          color: $black;
          margin-bottom: 10px;
          text-transform: uppercase;
          @include media_query(XL) {
            font-size: 18px;
            line-height: 25px;
          }
        }
        .table {
          margin-bottom: 20px;
          width: 100%;
          td {
            text-align: center;
            padding: 8px 0;
            min-width: 100px;
            img {
              width: 50px;
              @include media_query(XL) {
                width: 75px;
              }
            }
            @include media_query(XL) {
              min-width: 210px;
              padding: 23px 0;
            }
          }
        }
        &:first-child {
          .table {
            margin-bottom: 20px;
            width: 100%;
            td {
              min-width: 83px;
              @include media_query(XL) {
                min-width: 160px;
              }
            }
          }
          @include media_query(XL) {
            padding-right: 22px;
          }
        }
        @include media_query(XL) {
          width: 634px;
        }
      }
      @include media_query(L) {
        padding: 0 55px;
      }
    }
    @include media_query(XL) {
      margin-bottom: 80px;
    }
  }
  .house_plan {
    margin-bottom: 24px;
    .inner_wrapper {
      padding: 0 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      .house_model {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
        border: 1px solid $black;
        img {
          width: 100%;
          height: 100%;
        }
        @include media_query(XL) {
          margin-bottom: 60px;
          width: 712px;
          height: 777px;
        }
      }
      .coming_soon_area {
        text-align: center;
        .img {
          margin: auto;
          margin-bottom: 25px;
        }
        .lbl {
          font-size: 21px;
          line-height: 21px;
        }
      }
      .house_plan_table {
        .table {
          margin-bottom: 20px;
          width: 100%;
          th {
            text-align: center;
            padding: 12px 0;
            width: 260px;
            @include media_query(XL) {
              padding: 26px 0;
            }
          }
          td {
            text-align: center;
            vertical-align: middle;
            padding: 12px 0;
            width: 260px;
            @include media_query(XL) {
              padding: 17px 0;
            }
          }
        }  
      }
      @include media_query(XL) {
        padding: 0 75px;
      }
    }
    @include media_query(XL) {
      margin-bottom: 80px;
    }
  }
  .info_icon {
    margin-left: 5px;
    display: flex;
    position: relative;
    width: 13px;
    height: 13px;
    img {
      width: 100%;
      height: 100%;
    }
    .tooltip {
      display: none;                
      background: $white;
      border: 1px solid $dragon_green;
      border-radius: 7px;
      position: absolute;
      top: -300%;
      right: 25px;
      width: 170px;
      height: 170px;
      z-index: 9;
      padding: 10px;
      &::before {
        width: 0; 
        height: 0; 
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent; 
        border-left:10px solid $dragon_green; 
        position: absolute;
        content: '';
        right: -10px;
        top: 20%;
        @include media-query(L) {
          top: 35%;
        }
      }
      &::after {
        width: 0; 
        height: 0; 
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent; 
        border-left:10px solid $white; 
        position: absolute;
        content: '';
        right: -8.5px;
        top: 20%;
        @include media-query(L) {
          top: 35%;
        }
      }
      .tooltiptext {
        min-height: 35px;
        max-height: 150px;
        overflow-y: auto;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: #000000;
        .title {
          font-weight: 600;
        }
        @include media-query(L) {
          max-height: 180px;
          font-size: 21px;
          line-height: 30px;
        }
      }
      @include media_query(L) {
        padding: 15px 10px 15px 15px;
        top: -250%;
        right: 35px;
        width: 364px;
        max-height: 200px;
      }
    }
    &:hover {
      .tooltip {
        display: flex;
      }
    }
    @include media_query(L) {
      width: 24px;
      height: 24px;
    }
  }
  .house_costing_sheet {
    .inner_wrapper {
      //padding: 0 15px;
      .house_details {
        .main_title {
          font-weight: 700;
          font-size: 18px;
          line-height: 18px;
          margin-bottom: 20px;
          color: $dragon_green;
          @include media_query(XL) {
            font-size: 28px;
            line-height: 28px;
            margin-bottom: 30px;
          }
        }
        .details_container {
          background-color: #F5F5F5;
          color: $black;
          border: 1px solid #D6DEF1;
          padding: 15px;
          margin-bottom: 20px;
          .details_title_area {
            margin-bottom: 20px;
            .details_title {
              font-weight: 600;
              font-size: 16px;
              line-height: 18px;
              @include media_query(XL) {
                font-size: 24px;
                line-height: 38px;
              }
            }
            .details_desc {
              font-weight: 400;
              font-size: 16px;
              line-height: 18px;
              display: flex;
              align-items: center;
              @include media_query(XL) {
                font-size: 24px;
                line-height: 38px;
              }
            }
          } 
          .inner_wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            padding: 0;
            .white_box {
              background-color: $white;
              width: 100%;
              margin-bottom: 15px;
              .top_area {
                padding: 15px;
                border-bottom: 1px solid #666666;
                .main_lbl {
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 20px;
                  color: $black;
                  margin-bottom: 8px;
                  @include media_query(XL) {
                    font-size: 24px;
                    line-height: 38px;
                    margin-bottom: 12px;
                  }
                }
                .details {
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 20px;
                  color: $black;
                  margin-bottom: 8px;
                  &:last-child {
                    margin-bottom: 0;
                  }
                  @include media_query(XL) {
                    font-size: 24px;
                    line-height: 38px;
                    margin-bottom: 12px;
                  }
                }
                @include media_query(XL) {
                  padding: 20px 30px;
                }
              }
              .bottom_area {
                padding: 15px;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: $black;
                @include media_query(XL) {
                  padding: 20px 30px;
                  font-size: 24px;
                  line-height: 38px;
                }
              }
              @include media_query(L) {
                width: 320px;
                margin-bottom: 25px;
              }
              @include media_query(XL) {
                width: 590px;
              }
            }
            @include media_query(L) {
              flex-direction: row;
            }
          }
          .total_cost {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: $black;
            @include media_query(XL) {
              font-size: 24px;
              line-height: 38px;
            }
          }
          @include media_query(L) {
            padding: 20px 30px;
          }
        }
        .details_total_cost {
          background: #F5F5F5;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          padding: 15px  15px 15px 10px;
          color: $black;
          margin-bottom: 20px;
          border-left: 8px solid $dragon_green;
          @include media_query(XL) {
            font-size: 24px;
            line-height: 24px;
            padding: 20px 30px 20px 19px;
            margin-bottom: 40px;
            border-left: 11px solid $dragon_green;
          }
        }
      }
      .grand_total {
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        color: $white;
        background: $dragon_green;
        padding: 15px;
        margin-bottom: 40px;
        @include media_query(XL) {
          font-size: 24px;
          line-height: 30px;
          padding: 20px 30px;
          margin-bottom: 80px;
        }
      }
      // @include media_query(XL) {
      //   padding: 0 75px;
      // }
    }
    .section_wrapper {
      padding: 0 15px;
      @include media_query(XL) {
        padding: 0 75px;
      }
      .water_mark_container {
        padding: 0;
        margin-bottom: 20px;
      }
      .feature_table {
        background-color: $light_cream_2;
        border: 1px solid $border_color;
        margin-bottom: 20px;
        @include media-query(XL) {
          margin-bottom: 40px;
        }
        .feature_collapsed_area {
          display: none;
          padding: 12px 0;
          @include media-query(M) {
            padding: 20px 0;
          }
        }
        .feature_header {
          background-color: $dragon_green;
          color: $white;
          padding: 8px 12px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 16px;
          line-height: 20px;
          font-weight: 600;
          cursor: pointer;
          @include media-query(M) {
            padding: 20px;
          }
          @include media-query(L) {
            font-size: 20px;
            line-height: 22px;
          }
          @include media-query(XXL) {
            font-size: 24px;
            line-height: 24px;
          }
          .right_icons {
            display: flex;
            align-items: center;
            .info_icon {
              margin-right: 12px;
              @include media_query(L) {
                height: 20px;
                width: 20px;
                margin-right: 24px;
              }
              @include media_query(XXL) {
                height: 26px;
                width: 26px;
              }
            }
            .arrow_icon {
              height: 13px;
              width: 13px;
              display: flex;
              align-items: center;
              justify-content: center;
              transform: rotate(180deg);
              img {
                width: 100%;
                height: 100%;
              }
              @include media_query(L) {
                height: 20px;
                width: 20px;
              }
              @include media_query(XXL) {
                height: 26px;
                width: 26px;
              }
              &.expand {
                transform: rotate(0deg);
              }
            }
          }
        }
        .feature_footer {
          background-color: $white;
          color: $dragon_green;
          padding: 8px 12px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 16px;
          line-height: 20px;
          font-weight: 600;
          @include media-query(M) {
            padding: 20px;
          }
          @include media-query(L) {
            font-size: 20px;
            line-height: 22px;
          }
          @include media-query(XXL) {
            font-size: 24px;
            line-height: 24px;
          }
          &.feature_footer_green {
            background-color: $dragon_green;
            color: $white;
          }
        }
        .sub_feature_table {
          background-color: $white;
          margin: 0 10px 10px;
          font-size: 14px;
          line-height: 22px;
          font-weight: 400;
          &:first-child {
            margin-top: 10px;
          }
          .title {
            font-weight: 600;
          }
          @include media-query(M) {
            &:first-child {
              margin-top: 20px;
            }
            margin: 0 20px 20px;
          }
          @include media-query(L) {
            font-size: 18px;
            line-height: 24px;
          }
          @include media-query(XXL) {
            font-size: 21px;
            line-height: 28px;
          }
          .sub_feature_header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid $border_color;
            padding: 15px;
            @include media-query(L) {
              padding: 15px 20px;
            }
          }
          .inner_wrapper {
            display: flex;
            flex-direction: column;
            @include media-query(L) {
              flex-direction: row;
            }
            .sub_feature_content {
              border-bottom: 1px solid $border_color;
              &:last-child {
                border-bottom: 0px;
              }
              @include media-query(L) {
                width: 50%;
                border-bottom: 0px;
                border-left: 1px solid $border_color;
                &:first-child {
                  border-left: 0px;
                }
              }
            }
          }
          .sub_feature_content {
            .content {
              padding: 15px;
              @include media-query(L) {
                padding: 30px;
              }
            }
            .cost {
              padding: 10px 15px;
              border-top: 1px solid $border_color;
              @include media-query(L) {
                padding: 20px 30px;
              }
            }
          }
          .sub_feature_footer {
            border-top: 1px solid $border_color;
            font-weight: 600;
            padding: 15px;
            @include media-query(L) {
              padding: 30px;
            }
          }
        }
      }
      .grand_total {
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        color: $blue;
        background: #FFD54F;
        padding: 14px 20px;
        margin-bottom: 14px;
        @include media_query(XL) {
          font-size: 24px;
          line-height: 30px;
          padding: 20px 30px;
          margin-bottom: 26px;
        }
      }
    }
  }
  .material_quantity_sheet {
    .inner_wrapper {
      padding: 0 15px;
      margin-bottom: 24px;
      .water_mark_container {
        padding: 0;
        margin-bottom: 20px;
      }
      .table_area {
        margin-bottom: 20px;
        overflow-x: auto;
        .quantity_table {
          width: 100%;
          border: 2px solid $black;
          .heading {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            min-width: 110px;
            color: $black;
            @include media_query(XL) {
              font-size: 24px;
              line-height: 38px;
            }
          }
            .heading_sub_material {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              min-width: 70px;
              color: $black;
              padding:5px;
              @include media_query(XL) {
                font-size: 24px;
                line-height: 38px;
              }
          }
          th {
            padding: 8px;
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            color: $black;
            border-bottom: 2px solid $black;
            text-align: left;
            @include media_query(XL) {
              padding: 24px;
              font-size: 21px;
              line-height: 21px;
            }
          }
          td {
            padding: 8px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            min-width: 70px;
            color: $black;
            @include media_query(XL) {
              font-size: 21px;
              line-height: 38px;
              padding: 20px 24px;
            }
          }
        }
        @include media_query(XL) {
          margin-bottom: 40px;
        }
      }
      .grand_total {
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        color: $blue;
        background: #FFD54F;
        padding: 14px 20px;
        margin-bottom: 14px;
        @include media_query(XL) {
          font-size: 24px;
          line-height: 30px;
          padding: 20px 30px;
          margin-bottom: 26px;
        }
      }
      .material_total {
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        color: $white;
        background: $dragon_green;
        padding: 14px 20px;
        margin-bottom: 14px;
        @include media_query(XL) {
          font-size: 24px;
          line-height: 30px;
          padding: 20px 30px;
          margin-bottom: 26px;
        }
      }
      .congratulate_container {
        background: $blue;
        padding: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        .left_img, .right_img{
          width: 52px;
          height: 49px;
          display: none;
          @include media_query(L) {
            display: flex;
          }
          @include media_query(XL) {
            width: 72px;
            height: 69px;
          }
        }
        .center_txt {
          .title_area {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
            .img {
              width: 30px;
              height: 30px;
              @include media_query(XL) {
                width: 60px;
                height: 60px;
              }
            }
            .title {
              color: $white;
              font-weight: 600;
              font-size: 21px;
              line-height: 30px;
              text-align: center;
              margin: 0 10px;
              @include media_query(XL) {
                font-size: 34px;
                line-height: 45px;
                margin: 0 20px;
              }
            }
          }
          .txt {
            color: $white;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            @include media_query(XL) {
              font-size: 34px;
              line-height: 45px;
            }
          }
        }
        @include media_query(L) {
          flex-direction: row;
        }
        @include media_query(XL) {
          padding: 44px 66px;
        }
      }
      @include media_query(XL) {
        padding: 0 75px;
        margin-bottom: 80px;
      }
    }
  }
  .blogs {
    margin-bottom: 24px;
    .inner_wrapper {
      padding: 0 15px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
      margin-bottom: 26px;
      .blog_box {
        width: 100%;
        max-width: 400px;
        margin-bottom: 25px;
        border: 1px solid #101627;
        cursor: pointer;
        .img_area {
          width: 100%;
          height: 230px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .txt_area {
          padding: 25px 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .lbl {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 5px;
            color: $blue;
            text-align: center;

          }
          .txt {
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;
            color: $blue;
            text-align: center;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
        @include media_query(L) {
          margin-right: 25px;
          margin-bottom: auto;
          width: 400px;
          height: 460px;
          &:last-child {
            margin-right: 0;
            margin-bottom: auto;
          }
        }
        @include media_query(XL) {
          height: 430px;
        }
        @include media_query(XXL) {
          height: 386px;
        }
      }
      @include media_query(L) {
        flex-direction: row;
      }
      @include media_query(XL) {
        padding: 0 75px;
      }
    }
    .btn_area {
      display: flex;
      align-items: center;
      justify-content: center;
      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        background: $dragon_green;
        border-radius: 8px;
        color: $white;
        text-transform: uppercase;
        padding: 13px 32px;
        font-weight: 700;
        cursor: pointer;
        text-decoration: none;
      }
    }
    @include media_query(XL) {
      margin-bottom: 80px;
    }
  }
  .stay_connected {
    .inner_wrapper {
      padding: 0 15px;
      display: flex;
      justify-content: space-between;    
      .get_in_touch {
        margin-bottom: 40px;
        .lbl {
          font-weight: 500;
          font-size: 18px;
          line-height: 18px;
          color: $black;
          margin-bottom: 25px;
          text-transform: uppercase;
        }
        .txt {
          margin-bottom: 25px;
          font-weight: 500;
          color: $black;
          font-size: 18px;
          line-height: 18px;
          display: flex;
          align-items: center;
          text-decoration: none;
          .img {
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
          }
        }
      }
      .follow_us {
        margin-bottom: 40px;
        .lbl {
          font-weight: 500;
          font-size: 18px;
          line-height: 18px;
          color: $black;
          margin-bottom: 25px;
          text-transform: uppercase;
        }
        .icon_area {
          display: flex;
          align-items: center;
          .icon {
            width: 54px;
            height: 54px;
            margin-right: 20px;
            cursor: pointer;
          }
        }
      }
      .download_area {
        margin-bottom: 40px;
        text-align: right;
        display: none;
        .lbl {
          font-weight: 500;
          text-transform: uppercase;
          font-size: 18px;
          line-height: 18px;
          color: $black;
          margin-bottom: 25px;
        }
        .img_area {
          display: flex;
          align-items: center;
          .qr_code_img {
            width: 145px;
            height: 145px;
            padding: 10px;
            margin-right: -15px;
          }
          .google_play_img {
            width: 150px;
            height: 43px;
          }
        }
        @include media_query(L) {
          display: block;
        }
      }
      @include media_query(XL) {
        padding: 0 75px;
      }
    }
  }
  .disclaimer {
    .inner_wrapper {
      padding: 0 15px;
      .info_box {
        border: 1px solid $black;
        padding: 15px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: $black;
        margin-bottom: 20px;
        p {
          //margin: 0 0 16px;
          margin: 0 0 16px;
          font-size: 14px;
          line-height: 18px;
          &:last-child {
            margin: 0;
          }
          @include media_query(XL) {
            font-size: 21px;
            line-height: 28px;
          }
        }
        @include media_query(XL) {
          padding: 25px;
          font-size: 21px;
          line-height: 28px;
        }
      }
      @include media_query(L) {
        padding: 0 25px;
      }
      @include media_query(XL) {
        padding: 0 75px;
      }
    }
  }
  .footer {
    padding: 0 15px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    @include media_query(M) {
      flex-direction: row;
    }
    .link_area {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 12px;
      position: relative;
      padding-bottom: 8px;
      margin-bottom: 8px;
      &:nth-child(1){
        cursor: auto;
      }
      a {
        text-decoration: none;
      }
      .img {
        margin-right: 5px;
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
        }
      }
      @include media_query(L) {
        font-size: 14px;
        line-height: 14px;
        margin-bottom: 0;
        padding: 0;
        padding-right: inherit;
        margin-right: auto;
        padding-bottom: 0;
        margin-bottom: 0;
        border-right:  1px solid $blue;

        &:nth-child(4){
          border-right:  0px solid $blue !important;
        }
      }
    }
    @include media_query(XL) {
      padding: 0 60px;
      margin-bottom: 80px;
    }
  }
}