.sign-up-container {
  padding: 18px 26px;
  margin: 65px auto auto;
  max-width: 540px;
  @include media_query(L) {
    margin: 104px auto auto;
    width: auto;
    max-width: 100%;
  }
  .sign-up-text {
    font-weight: 600;
    font-size: 22px;
    line-height: 133.4%;
    color: $dragon_green;
    margin-bottom: 25px;
  }

  .custom-error {
    font-weight: 500;
    font-size: 12px;
    line-height: 133.4%;
    color: $error_orange;
    margin-bottom: 10px;
    @include media_query(L) {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 18px;
      line-height: 133.4%;
      position: absolute;
      top: -30px;
    }
  }

  .form-wrapper {
    margin-bottom: 51px;
    position: relative;
    @include media_query(L) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-bottom: 35px;
      position: relative;
    }

    .feild-container {
      &.left {
        width: calc(50% - 20px);
        max-width: 483px;
        @include media_query(XXL) {
          max-width: 470px;
          width: calc(50% - 25px);
        }
      }

      &.right {
        width: calc(50% - 20px);
        max-width: 483px;
        @include media_query(XXL) {
          max-width: 470px;
          width: calc(50% - 25px);
        }
      }
    }

    .input-error {
      border: 1px solid $error_orange;
    }

    .input-container {
      height: 30px;
      width: 24px !important;
      margin-right: 9px;
      margin-bottom: 35px;
      background: $light_cream_1;
      border: 1px solid $gray91;
      box-sizing: border-box;
      border-radius: 4px;
      font-weight: 500;
      @media only screen and (max-width: 320px) {
       margin-right: 12px;
      }
      @include media_query(M) {
        height: 44px;
        width: 36px !important;
        margin-right: 14px;
      }
      @include media_query(L) {
        margin-bottom: 45px;
        height: 36px;
        width: 26px !important;
        margin-right: 8px;
      }
      @include media_query(XL) {
        width: 34px!important;
        height: 45px;
        margin-right: 12px;
        font-size: 18px;
      }
      @include media_query(XXL) {
        width: 36px!important;
      }
    }

    .error-container {
      font-weight: 500;
      font-size: 12px;
      line-height: 133.4%;
      color: $error_orange;
      margin-bottom: 7px;
      position: absolute;
      top: -16px;

      @include media_query(L) {
        line-height: 133.4%;
        top: -30px;
        font-size: 18px;
      }
    }

    .label-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 13px;

      .img-wrapper {
        height: 14px;
        width: 15px;
        margin-right: 8px;
        @include media_query(L) {
          width: 16px;
          height: 18px;
        }
        .img {
          height: 100%;
          width: 100%;
        }
      }

      .label-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 133.4%;
        color: $blue;

        @include media_query(L) {
          font-weight: 500;
          font-size: 18px;
          line-height: 18px;
        }
      }
    }

    .form-input {
      background: $light_cream_1;
      border: 1px solid $gray91;
      box-sizing: border-box;
      border-radius: 8px;
      height: 44px;
      width: 100%;
      margin-bottom: 25px;
      padding-left: 14px;
      font-weight: 500;
      @include media_query(L) {
        font-size: 16px;
        line-height: 16px;
        max-width: 474px;
        margin-bottom: 52px;
        height: 36px;
      }
      @include media_query(XL) {
        font-size: 18px;
        line-height: 18px;
        max-width: unset;
        height: 44px;
      }
      &::placeholder {
        color:#666666;
      }
    }

    .input-error-margin {
      margin-bottom: 7px;
    }

    .input-error {
      border: 1px solid $error_orange;
    }

    .btn-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .next-btn-container {
        background: $dragon_green_1;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
        border-radius: 6px;
        color: $white;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        border-color: $dragon_green_1;
        border: none;
        cursor: pointer;
        width: 237px;
        height: 40px;

        @include media_query(L) {
          font-weight: 600;
          font-size: 20px;
          line-height: 133.4%;
          width: 272px;
          height: 64px;
          box-shadow: none;
        }
      }
    }
  }

  .existing-user-container {
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    justify-content: center;

    @include media_query(L) {
      font-size: 18px;
      line-height: 18px;
    }

    .existing-user {
      color: $blue;
      margin-right: 5px;
      font-weight: 400;
    }

    .login-wrapper {
      color: $dragon_green;
      font-weight: 700;
    }
  }
  .logo-with-text-container {
    @include media_query(L) {
      margin-bottom: 40px;
    }
  }
  @include media_query(XXL) {
    padding: 22px 85px;
  }
}

.or-sideline-wrapper-l-signup {
  margin-left: 0px;
  margin-right: 10px;
  margin-bottom: 0px;
  margin-top: 0px;

  .or-sideline-l-signup {
    border-right: 1px solid $blue;
    height: 40px;
  }
}

.or-container-l-signup {
  font-weight: 600;
  font-size: 14px;
  line-height: 133.4%;
  display: flex;
  flex-direction: column;
  color: $blue;
  justify-content: center;
  align-content: center;
  margin: 0 20px 0 20px;
  position: relative;
  @include media_query(L) {
    bottom: -54px;
  }
  @include media_query(XXL) {
    margin: 0 40px 0 40px;
  }
}

.or-text-signup{
  padding-top: 5px;
  padding-bottom: 5px;
}

.or-container {
  font-weight: 600;
  font-size: 14px;
  line-height: 133.4%;
  display: flex;
  color: $blue;
  justify-content: center;
  align-content: center;
  margin-bottom: 35px; 
  margin-top: 5px;

  @include media_query(L) {
    width: 20%;
  }

  .or-sideline-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;

    .or-sideline {
      border-bottom: 1px solid $blue;
      height: 0px;
    }
  }
  .left_line {
    margin-left: 0;
  }
  .right_line {
    margin-right: 0;
  }
}
