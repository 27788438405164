.edit-house-name-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(53, 52, 52, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  overflow-x: hidden;
  overflow-y: auto;

  .edit-house-name-inner-container {
    width: auto;
    height: auto;
    padding: 20px;
    background-color: white;

    .edit-logo {
      color: $dragon_green;
      font-weight: 500;
      font-size: 21px;
      line-height: 21px;
      display: flex;
      justify-content: center;
      padding-bottom: 32px;
    }

    .form-input {
      margin-bottom: 30px;
      height: 45px;
      background: $light_cream_1;
      border: 1px solid $gray91;
      box-sizing: border-box;
      border-radius: 8px;
      padding-left: 15px;

      &.error {
        border-color: $error_orange;
      }
    }

    .rename-button {
      background: $dragon_green_1;
      border-radius: 6px;
      height: 40px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
    }
  }
}
