.login-container {
  padding: 22px 26px;
  max-width: 540px;
  margin: 65px auto auto;
  @include media_query(L) {
    padding: 22px 35px;
    width: auto;
    max-width: 100%;
    margin: 104px auto auto;
  }
  @include media_query(XL) {
    padding: 22px 85px;
  }

  .error-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 133.4%;
    color: $error_orange;
    position: absolute;
    top: -20px;

    @include media_query(L) {
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
      font-size: 16px;
      top: -30px;
    }
  }

  .error-text1 {
    font-weight: 500;
    font-size: 14px;
    line-height: 133.4%;
    color: $error_orange;
    margin-bottom: 7px;
    padding-left: 400px;

    @include media_query(L) {
      margin-left: 10%;
      margin-bottom: 20px;
    }
  }

  .error-text2 {
    font-weight: 500;
    font-size: 14px;
    line-height: 133.4%;
    color: $error_orange;
    margin-bottom: 7px;
    padding-left: 400px;

    @include media_query(L) {
      margin-left: 3%;
      margin-bottom: 20px;
    }
  }

  .login-text {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 133.4%;
    color: $dragon_green;
    margin-bottom: 25px;
  }

  .login-text-error-margin {
    margin-bottom: 25px;
  }

  .new-to-visava-container {
    display: flex;
    justify-content: center;
    font-size: 14px;
    line-height: 14px;
    margin-top: 25px;
    font-weight: 400;

    @include media_query(L) {
      margin-top: 35px;
      font-size: 18px;
      line-height: 18px;
    }

    .sign-up {
      font-weight: 700;
      margin-left: 3px;
      color: $dragon_green_1;
    }
  }

  .form-wrapper {
    @include media_query(L) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .label-wrapper {
      display: flex;
      margin-bottom: 13px;

      .img-wrapper {
        height: 17px;
        width: 17px;
        margin-right: 5px;
        @include media_query(L) {
          height: 24px;
          width: 24px;
        }
        .img {
          height: 100%;
          width: 100%;
        }
      }
      .mobile_img {
        width: 13px;
      }

      .label-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 133.4%;
        color: $blue;

        @include media_query(L) {
          font-size: 16px;
        }
      }
    }

    .label-wrapper1 {
      display: flex;
      margin-bottom: 25px;
      padding-left: 80px;

      .img-wrapper {
        height: 17px;
        width: 17px;
        margin-right: 5px;

        .img {
          height: 100%;
          width: 100%;
        }
      }

      .label-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 133.4%;
        color: $blue;

        @include media_query(L) {
          font-size: 16px;
        }
      }
    }

    .form-input {
      background: $light_cream_1;
      border: 1px solid $gray91;
      box-sizing: border-box;
      border-radius: 8px;
      height: 44px;
      width: 100%;
      margin-bottom: 21px;
      padding-left: 15px;
      font-weight: 500;
    }

    .form-input1 {
      background: $light_cream_1;
      border: 1px solid $gray91;
      box-sizing: border-box;
      border-radius: 8px;
      height: 44px;
      width: 80%;
      margin-bottom: 0px;
      padding-left: 15px;
    }

    .input-error-margin {
      margin-bottom: 7px;
    }

    .input-container {
      height: 30px;
      width: 24px !important;
      margin-right: 8px;
      margin-bottom: 40px;
      background: $light_cream_1;
      border: 1px solid $gray91;
      box-sizing: border-box;
      border-radius: 4px;
      font-weight: 500;
      @include media_query(M) {
        height: 44px;
        width: 36px !important;
        margin-right: 14px;
      }
      @include media_query(XL) {
        height: 45px;
        width: 36px !important;
        margin-right: 12px;
      }
    }

    .input-container1 {
      width: 24px !important;
      height: 30px;
      margin-right: 25px;
      margin-bottom: 0px;
      background: $light_cream_1;
      border: 1px solid $gray91;
      box-sizing: border-box;
      border-radius: 4px;
      @include media_query(M) {
        height: 40px;
        width: 30px !important;
        margin-right: 7px;
      }  
      @include media_query(XL) {
        height: 45px;
        width: 36px !important;
        margin-right: 12px;
      }
    }
    .input-error {
      border: 1px solid $error_orange;
    }

    .or-container {
      font-weight: 600;
      font-size: 14px;
      line-height: 133.4%;
      display: flex;
      color: $blue;
      justify-content: center;
      align-content: center;
      margin-bottom: 35px; 
      margin-top: 5px;

      @include media_query(L) {
        width: 20%;
      }

      .or-sideline-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 10px;
        margin-right: 10px;

        .or-sideline {
          border-bottom: 1px solid $blue;
          height: 0px;
        }
      }
      .left_line {
        margin-left: 0;
      }
      .right_line {
        margin-right: 0;
      }
    }

    .btn-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 20px;
      @include media_query(L) {
        margin-top: 50px;
      }

      .next-btn-container {
        background: $dragon_green_1;
        // box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
        border-radius: 6px;
        width: 233px;
        height: 40px;
        color: $white;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        border-color: $dragon_green;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;

        @include media_query(L) {
          font-weight: 600;
          font-size: 20px;
          line-height: 133.4%;
          height: 56px;
          width: 272px;
        }
        @include media_query(L) {
          height: 64px;
        }
      }
    }
  }
  .or-container-l {
    margin: 0 20px 0 20px;
    @include media_query(XXL) {
      margin: 0 40px 0 40px;
    }
  }
  .input-wrapper {
    @include media_query(XXL) {
      max-width: 483px !important;
     }
    .digit_wrapper {
      & > div {
        width: 100%;
       & > div {
          width: 10%;
          margin-right: 5px;
          &:last-child {
            margin-right: 0;
          }
          @include media_query(XL) {
            margin-right: 8px;
          }
          @include media_query(XXL) {
            margin-right: 12px;
            width: 48px;
          }
          .input-container {
            width: 100% !important;
          }
        }
      }
    }
  }
}

.or-container-l {
  font-weight: 600;
  font-size: 14px;
  line-height: 133.4%;
  display: flex;
  flex-direction: column;
  color: $blue;
  justify-content: center;
  align-content: center;
  margin-bottom: 35px;
  margin-top: 0px;
  @include media_query(L) {
    font-size: 16px;
    margin-left: 1.5%;
    margin-right: 1.5%;
  }
  @include media_query(XL) {
    margin-left: 5%;
    margin-right: 5%;
  }

  .or-sideline-wrapper-l {
    margin-left: 0px;
    margin-right: 10px;
    margin-bottom: 0px;
    margin-top: 0px;

    .or-sideline-l {
      border-right: 1px solid $blue;
      height: 50px;
    }
  }

  .or-text{
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.form-wrapper-l {
  @include media_query(L) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .input-wrapper {
    width: calc(50% - 25px);
    max-width: 477px;
    display: flex;
    flex-direction: column;
  }
  .label-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 13px;

    .img-wrapper {
      height: 17px;
      width: 17px;
      margin-right: 10px;

      @include media_query(L) {
        height: 16px;
        width: 18px;
      }

      .img {
        height: 100%;
        width: 100%;
      }
    }
    .mobile_img {
      width: 13px;
    }

    .label-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 133.4%;
      color: $blue;

      @include media_query(L) {
        font-size: 16px;
      }
    }
  }

  .label-wrapper1 {
    display: flex;
    margin-bottom: 25px;
    padding-left: 80px;

    .img-wrapper {
      height: 17px;
      width: 17px;
      margin-right: 5px;

      @include media_query(L) {
        height: 20px;
        width: 20px;
      }

      .img {
        height: 100%;
        width: 100%;
      }
    }

    .label-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 133.4%;
      color: $blue;

      @include media_query(L) {
        font-size: 16px;
      }
    }
  }

  .form-input {
    background: $light_cream_1;
    border: 1px solid $gray91;
    box-sizing: border-box;
    border-radius: 8px;
    height: 44px;
    width: 100%;
    margin-bottom: 21px;
    padding-left: 15px;
    font-weight: 500;
    @include media_query(L) {
      margin-bottom: 40px;
    }
    @include media_query(XL) {
      font-size: 18px;
    }
    &::placeholder {
      color:#666666;
    }
  }

  .form-input1 {
    background: $light_cream_1;
    border: 1px solid $gray91;
    box-sizing: border-box;
    border-radius: 8px;
    height: 44px;
    width: 80%;
    margin-bottom: 0px;
    padding-left: 5px;
  }

  .input-error-margin {
    margin-bottom: 7px;
  }

  .input-container {
    width: 24px !important;
    height: 30px;
    margin-bottom: 40px;
    background: $light_cream_1;
    border: 1px solid $gray91;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 500;
    @include media_query(M) {
      height: 40px;
      width: 30px !important;
    }  
    @include media_query(XXL) {
      height: 45px;
      width: 36px !important;
      font-size: 18px;
    }
  }

  .input-container1 {
    width: 24px !important;
    height: 30px;
    margin-right: 25px;
    margin-bottom: 0px;
    background: $light_cream_1;
    border: 1px solid $gray91;
    box-sizing: border-box;
    border-radius: 4px;
    @include media_query(M) {
      height: 40px;
      width: 30px !important;
      margin-right: 7px;
    }  
    @include media_query(XXL) {
      height: 45px;
      width: 36px !important;
      margin-right: 12px;
    }
  }
  .input-error {
    border: 1px solid $error_orange;
  }

  .or-container {
    font-weight: 600;
    font-size: 14px;
    line-height: 133.4%;
    display: flex;
    color: $blue;
    justify-content: center;
    align-content: center;
    margin-bottom: 35px;
    margin-top: 45px;

    @include media_query(L) {
      width: 20%;
    }

    .or-sideline-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 10px;
      margin-right: 10px;

      .or-sideline {
        border-bottom: 1px solid $blue;
        height: 0px;
      }
    }
    .left_line {
      margin-left: 0;
    }
    .right_line {
      margin-right: 0;
    }
  }

  .btn-container {
    margin-right: 64px;
    margin-left: 64px;
    height: 40px;

    @include media_query(L) {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 50px;
    }

    .next-btn-container {
      background: $dragon_green_1;
      // box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
      border-radius: 6px;
      width: 100%;
      height: 100%;
      color: $white;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      border-color: $dragon_green;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;

      @include media_query(L) {
        width: 25%;
      }
    }

    .disabled {
      background: lightcyan;
    }
  }
}
