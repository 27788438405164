.error-popup-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(53, 52, 52, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  overflow-x: hidden;
  overflow-y: auto;

  .error-popup-inner-container {
    border-radius: 8px;
    padding: 42px 20px 30px;
    width: 294px;
    height: auto;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    @include media_query(L) {
      width: 378px;
      padding: 42px 30px 30px;
    }
    .close-icon {
      position: absolute;
      top: 12px;
      right: 12px;
      background-image: url("../../../asset/images/close_icon_black.png");
      height: 22px;
      width: 22px;
      background-repeat: no-repeat;
      background-size: contain;          
      cursor: pointer;
    }
    .icon {
      width: 86px;
      height: 86px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 5px;
    }
    .error-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: $blue;
      margin-bottom: 15px;
      text-align: center;
      @include media_query(L) {
        font-size: 18px;
        line-height: 24px;
      }
    }
    .ok-button {
      background-color: $dragon_green;
      color: $white;
      font-size: 14px;
      line-height: 14px;
      font-weight: 600;
      padding: 13px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
      border-radius: 6px;
      width: 233px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .ok-button-feed-back {
      border: 1px solid $dragon_green;
      background-color: $white;
      color: $dragon_green;
      font-size: 14px;
      line-height: 14px;
      font-weight: 600;
      padding: 13px;
      margin-top: 10px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
      border-radius: 6px;
      width: 233px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .error-button { 
      border-radius: 4px;
      height: 40px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center; 
      cursor: pointer;
      margin-top: 15px; 
      color: #07afa6; 
      border: solid 1px #07afa6; 
    }

    .pay-now-button {
      width: 100%;
      background: $dragon_green_1;
      border-radius: 4px;
      height: 40px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      cursor: pointer;
      box-shadow: none;
      border: none;
    }
  }
}
