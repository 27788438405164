.directBomButtons {
    display: flex;
    flex-direction: column;

    .view-document {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $dragon_green_1;
        color: $white;
        height: 40px;
        border-radius: 8px;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        margin-bottom: 12px;
        padding: 0 12px;
        margin: 0 20px 20px;
    }

    .generate-document {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        color: $dragon_green_1;
        height: 40px;
        border-radius: 8px;
        border: solid 1px $dragon_green_1;
        margin-bottom: 12px;
        padding: 0 12px;
        margin: 0 20px 20px;
      }
}