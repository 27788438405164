.otp-container {
  padding: 25px 25px 25px 25px;
  height: calc(100vh - 50px);
  margin: 0;
  @include media_query(L) {
    // padding: 104px 25px 25px 25px;
    // height: calc(100vh - 130px);
  }
  .initial-text {
    font-weight: 600;
    font-size: 22px;
    line-height: 133.4%;
    color: $dragon_green_1;
    margin-bottom: 25px;
    margin-top: 25px;
    //margin-left: 22px;
    padding-top: 20px;

    @include media_query(L){
      margin-left: 180px;
      padding-top: 0;
    }
  }
  .logo-with-text-container {
    margin-top: 50px;
  }
  .inner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 133.4%;
    color: $blue;
    margin-bottom: 66px;
    text-align: center;
    //margin-left: 22px;
    .bolder {
      font-weight: 700;
    }

    @include media_query(L) {
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      width: 380px;
      margin: 0 auto 50px auto;
      font-size: 18px;
      line-height: 133.4%;
    }
  }

  .error-text {
    color: $error_orange;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 133.4%;
    margin-bottom: 10px;
    position: absolute;
    top: -36px;

    @include media_query(L) {
      display: flex;
      justify-content: center;
      // width: 330px;
      margin-left: 0;
      font-size: 18px;
      margin-bottom: 10px;
      font-weight: 500;
      line-height: 133.4%;
      text-align: left;
    }
  }

  .otp-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    position: relative;
      div {
        div {
        &:last-child {
          .input-container {
            margin-right: 0;
          }
        }
        }
      }
    .input-container {
      width: 36px !important;
      height: 57px;
      margin-right: 10px;
      margin-left: 9px;
      background: $light_cream_1;
      border: 1px solid $gray91;
      box-sizing: border-box;
      border-radius: 6px;
      font-weight: 600;
      @include media_query(L) {
        height: 55px;
        width: 44px!important;
        margin-left: 0;
        margin-right: 36px;
        font-size: 24px;
        line-height: 32px;
      }
    }

    .input-error {
      border: 1px solid $error_orange;
    }

    .error-container {
      font-weight: 500;
      font-size: 14px;
      line-height: 133.4%;
      display: flex;
      align-items: center;
      color: $error_orange;
      margin-bottom: 7px;
    }
  }

  .resend-code-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: normal;
    font-size: 12px;
    line-height: 128.91%;
    margin-bottom: 44px;
    font-weight: 400;
    color: $light_gray;

    @include media_query(L) {
      font-size: 18px;
      margin-bottom: 50px;
    }
    @include media_query(XL) {
      margin-bottom: 72px;
    }
  }

  .otp-failed {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $error_orange;

    .invalid-code { 
      width: 203px;
      display: flex; 
      justify-content: left; 

      @include media_query(L){
        width: 300px;
      }
    }
  }

  .resend-code {
    color: $dragon_green_1;
    margin-left: 3px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    @include media_query(L) {
      font-size: 18px;
      line-height: 23px;
    }
  }

  .resend-code1 {
    color: $dragon_green_1;
    font-weight: 700;
    text-decoration: underline;
  }

  .terms-condition-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 15px;

    @include media_query(L) {
      font-size: 14px;
      line-height: 133.4%;
    }
    @include media_query(XXL) {
      font-size: 18px;
      line-height: 133.4%;
    }
    .inner_wrapper {
      display: flex;
      .and_lbl {
        margin: 0 8px;
      }
    }
  }

  .otp-validation-wrapper {
    display: flex;
    justify-content: center;

    .otp-validation-button {
      background: $dragon_green_1;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      width: 237px;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      border-radius: 6px;
      margin-bottom: 25px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
      @include media_query(L) {
        height: 64px;
        width: 272px;
        border-radius: 4px;
        font-weight: 600;
        font-size: 24px;
        line-height: 133.4%;
        margin-bottom: 36px;
        box-shadow: none;
      }
    }
  }
  .tnc-container {
    height: 100vh;
    top: 0;
  }
}
