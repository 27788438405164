.sample-doc-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(53, 52, 52, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 30;
  overflow-x: hidden;
  overflow-y: auto;

.sample-house-container {
  margin-left: 20px;
  margin-right: 20px;
  height: auto; 
  width: auto;
  background: $white;
 

  .house-types {
    color: $dragon_green;
    font-weight: 500;
    font-size: 21px;
    line-height: 21px;
    display: flex;
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 32px;
  }

  .document-wrapper {
    display: flex;
    align-items: center; 
    justify-content: space-between;
    padding-top: 36px;
    padding-bottom: 36px;
    padding-right: 36px;

    .image-wrapper {
      margin-left: 32px;
      margin-right: 10px;

    //   .image {}
    }

    .text {
      font-weight: 500;
      font-size: 14px;
      color: $blue;
      text-decoration: underline;
      padding-right: 40px;
    }

    .forward-arrow-wrapper {
      margin-left: 20px;
      margin-top: 5px;

      // .forward-image {}
    }
  }

  .seperator {
    border: 0.5px solid #192643;
    margin-bottom: 32px;
  }
}
}