.vendor_banner {
  background-color: $white;
  @include media_query(XL) {
    padding: 30px;
    padding-bottom: 0;
  }
  .inner_wrapper {
    padding: 10px;
    background-color: $blue;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @include media_query(M) {
      padding: 30px;
    }  
    @include media_query(L) {
      padding: 20px;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-around;
    }
    @include media_query(XL) {
      padding: 30px;
    } 
    .vendor_container {
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      @include media_query(L) {
        height: 100%;
        padding-top: 40px;
      }
      @include media_query(XL) {
        padding: 40px;
      }
      .coming_soon {
        width: 157px;
        height: 157px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        margin-bottom: 27px;
        text-align: center;
        border: 2px solid $dragon_green;
        border-radius: 50%;
        background-color: rgba($dragon_green, 0.1);
        @include media_query(L) {
          margin-bottom: 57px;
        }
        .txt {
          font-weight: 600;
          font-size: 23px;
          line-height: 133.4%;
          display: flex;
          flex-direction: column;
          .bolder {
            font-size: 33px;
            line-height: 40px;
          }
        }
      }
      .title {
        font-weight: 600;
        font-size: 28px;
        line-height: 133.4%;
        color: $white;
        margin-bottom: 18px;
        text-align: center;
        @include media_query(XL) {
          font-size: 33px;
        }
      }
      .sub_title {
        font-weight: 500;
        font-size: 18px;
        line-height: 155.4%;
        color: $white;
        text-align: center;
        @include media_query(XL) {
          font-size: 24px;
          text-align: left;
        }
      }
    }
  }
}
.vendor-form-container {
    padding: 40px 20px;  
    background-color: $white;
    width: 100%;
    max-width: 630px;
    @include media_query(XL){
      padding: 40px;  
    }
  
    .vendor-text {
      font-weight: 500;
      font-size: 22px;
      line-height: 133.4%;
      color: $blue;
      margin-bottom: 21px;
    }
  
    .vendor-form-wrapper {
      .form-wrapper {
        display: flex;
        flex-direction: column;
        position: relative;
  
        .error-text {
          color: $error_orange;
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 5px;
          position: absolute;
          top: -25px;
        }
  
        .form-label {
          margin-bottom: 10px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 128.91%;
          color: $blue;
          display: flex;
          @include media_query(L) {
            font-size: 16px;
          }
          @include media_query(XL) {
            font-size: 18px;
          }
          .img-wrapper {
            height: 14px;
            width: 15px;
            margin-right: 8px;
            @include media_query(L) {
              width: 16px;
              height: 18px;
            }
            .img {
              height: 100%;
              width: 100%;
            }
          }
        }
  
        .form-input {
          margin-bottom: 20px;
          height: 38px;
          background: $light_cream_1;
          border: 1px solid $gray91;
          box-sizing: border-box;
          border-radius: 8px;
          padding-left: 12px;
          font-size: 14px;
          line-height: 16px;
  
          @include media_query(L) {
            margin-bottom: 30px;
            height: 45px;
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
          }
  
          &.error {
            border-color: $error_orange;
          }
        }
  
        .form-submit {
          background: $dragon_green_1;
          border-radius: 6px;
          height: 40px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $white;
          cursor: pointer;
          margin: auto;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          @include media_query(M) {
            width: 272px;
            font-size: 16px;
            line-height: 18px;
          }
          @include media_query(XL) {
            height: 64px;
            width: 272px;
            font-size: 24px;
            line-height: 32px;
          }
        } 
      }
    }
  }
  .visava-vendor-container {
    margin: auto;
    padding: 40px 20px;
    @include media_query(M) {
      padding: 40px 30px;
    }
    @include media_query(L) {
      padding: 60px 40px;
    }
    @include media_query(XL) {
      padding: 80px 60px;
    }
    @include media_query(XXL) {
      padding: 60px 180px;
    }
    .vendor_inner_wrapper {
      .visava-vendor-label {
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
        color: $blue;
        margin-bottom: 30px;
  
        @include media_query(L) {
          display: flex;
          justify-content: center;
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 50px;
        }
        @include media_query(XL) {
          font-size: 33px;
          line-height: 44px;
        }
        @include media_query(XXL) {
          margin-bottom: 100px;
        }
      }
      .heading {
        font-weight: 500;
        font-size: 22px;
        line-height: 29px;
        align-items: center;
        color: $blue;
        margin-bottom: 10px;
  
        @include media_query(L) {
          font-size: 28px;
          line-height: 34px;
          margin-bottom: 17px;
        }
        @include media_query(XL) {
          font-size: 33px;
          line-height: 44px;
          margin-bottom: 27px;
        }
      }
  
      .description {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: $blue;
        margin-bottom: 50px;
  
        @include media_query(L) {
          font-size: 18px;
          line-height: 24px;
        }
        @include media_query(XL) {
          font-size: 24px;
          line-height: 37px;
        }
        .bold {
          font-weight: 700;
        }
      }
      .visava-steps-wrapper {
        @include media_query(L) {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .step-image {
          max-width: 250px;
          margin-bottom: 30px;
          @include media_query(M) {
           max-width: 250px;
          }
          @include media_query(XL) {
            max-width: 300px;
            margin-bottom: 0px;
           }
           @include media_query(XXL) {
            max-width: 360px;
           }
        }
      }
      .model-image {
        width: 205px;
        margin-bottom: 30px;
  
        @include media_query(M) {
          width: 100%;
          max-width: 250px;
        }
        @include media_query(XL) {
          width: 100%;
          max-width: 350px;
          margin-top: 30px;
        }
        @include media_query(XXL) {
          width: 100%;
          max-width: 470px;
          margin-top: 0px;
         }
      }
      .business-image {
        width: 205px;
        margin-bottom: 30px;
        
        @include media_query(M) {
          width: 100%;
          max-width: 250px;
        }
        @include media_query(XL) {
          width: 100%;
          max-width: 300px;
        }
        @include media_query(XXL) {
          width: 100%;
          max-width: 350px;
         }
      }
      .item {
        img {
          height: 100%;
          width: 100%;
        }
        @include media_query(XXL) {
          margin-bottom: 60px;
        }
        &:last-child {
          margin-bottom: 0;
          .model-image,.description {
            margin-bottom: 0;
          }
        }
      }
    }
    .desktop_view {
      display: none;
      @include media_query(L) {
        display: block;
      }
    }
    .mobile_viw {
      @include media_query(L) {
        display: none;
      }
    }
  }

.build-house-container {
  display: flex;
  justify-content: center;
  padding: 36px 20px;
  background-color: $light_cream_2;
  @include media_query(M) {
    padding: 36px 30px;
  }
  @include media_query(L) {
    padding: 36px 40px;
  }
  @include media_query(XL) {
    padding: 70px 80px;
  }
  .build-house-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .build-house-txt {
      font-weight: 500;
      font-size: 22px;
      line-height: 29px;
      display: flex;
      align-items: center;
      color: $blue;
      margin-bottom: 30px;
      letter-spacing: .2px;

      @include media_query(L) {
        font-size: 33px;
        line-height: 44px;
        margin-bottom: 44px;
      }
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      height: 40px;
      width: 272px;
      background: $dragon_green_1;
      box-sizing: border-box;
      font-weight: 600;
      font-size: 14px;
      color: $white;
      cursor: pointer;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);

      @include media_query(L) {
        width: 298px;
        height: 64px;
        font-weight: 600;
        font-size: 24px;
        line-height: 133.4%;
      }
    }
  }
}
.phone-input-container {
  width: 24px !important;
  height: 30px;
  margin-right: 9px;
  margin-bottom: 25px;
  background: $light_cream_1;
  border: 1px solid $gray91;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 500;
  @media only screen and (max-width: 320px) {
   margin-right: 4px;
  }
  @include media_query(M) {
    height: 40px;
    width: 28px !important;
  }  
  @include media_query(XL) {
    width: 34px!important;
    height: 45px;
    margin-right: 12px;
    font-size: 18px;
  }
  @include media_query(XXL) {
    width: 36px!important;
    margin-right: 18px;
  }
}
.vendor-or-container {
  font-weight: 600;
  font-size: 14px;
  line-height: 133.4%;
  display: flex;
  color: $blue;
  justify-content: center;
  align-content: center;
  margin-bottom: 14px; 
  @include media_query(L) {
    font-size: 18px;
    line-height: 133.4%;
  }
  .or-sideline-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 24px;
    margin-right: 24px;

    .or-sideline {
      border-bottom: 1px solid $blue;
      height: 0px;
    }
  }
  .left_line {
    margin-left: 0;
  }
  .right_line {
    margin-right: 0;
  }
}