::placeholder { 
  color: $color_placeholder;
}
// Banner Image
.banner-container-top{
  margin-top: 65px; 

  @include media_query(L) {
    margin-top: 104px;
  }
}


#dashboardImage {

}
.join_community_strip {
  background-color: $blue;
  color: $white;
  padding: 5px 15px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  img {
    margin-left: 4px;
    width: 8px;
    height: 7px;
  }
  @include media_query(L) {
    padding: 10px 20px;
    font-size: 16px;
    line-height: 19px;
    img {
      margin-left: 10px;
      width: auto;
      height: auto;
    }
  }
}
.feedback_btn {
  position: fixed;
  z-index: 10;
  bottom: 16px;
  right: 16px;
  background-color: $blue;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @include media_query(L) {
    padding: 12px;
    width: 192px;
    height: 42px;
    border-radius: 16px;
    border-bottom-right-radius: 0;
    right: 24px;
    bottom: 24px;
    img {
      width: 24px;
      height: 24px;
    }
  }
  .lbl {
    display: none;
    @include media_query(L) {
      margin-left: 8px;
      display: block;
      font-weight: 600;
      font-size: 14px;
      line-height: 133.4%;
      color: $white;
    }
  }
}
.dashboardImageContainer {
  display: flex;
  justify-content: flex-end;
  height: 280px;
  position: relative;
  background-color: $white; 
  @include media_query(S){
    height: 345px;
  }
  @include media_query(L){
    height: 420px;
  }
  @include media_query(XL){
    height: 535px;
  }
  @include media_query(XXL){
    height: 690px;
  }
  @media only screen and (min-width: 1600px) {
    height: 780px;
  }
  @media only screen and (min-width: 1900px) {
    height: 880px;
  }
  @media only screen and (min-width: 2200px) {
    height: 980px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: right;
  }
  .customerImage {
    display: none;
    @include media_query(M) {
      display: block;
    }
  }
  .customerImage_mobile {
    @include media_query(M) {
      display: none;
    }
  }
}

.dashboardImageLink {
  position: absolute;
  width: 42px;
  height: 32px;
  top: 14px;
  cursor: pointer;
  background: $white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  display: flex;
  align-items: center;

  @include media_query(L) {
    height: 59px;
    top: 30px;
    width: 190px;
  }

  .youtubeIcon {
    width: 28px;
    height: 22px;
    margin: 0 7px;

    @include media_query(L) {
      width: 35px;
      height: 25px;
      margin-left: 13px;
      margin-right: 0;
    }
  }

  .how-to-use {
    color: $blue;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    width: fit-content;
    @include media_query(L) {
      margin-left: 9px;
    }
  }
}

.googlePlayContainer {
  position: absolute;
  left: 40px;
  width: 360px;
  height: 306px;
  z-index: 5;
  margin: auto;
  top: 0;
  bottom: 0;
  @include media_query(XL) {
    width: 450px;
    height: 382px;
    left: 50px;
  }
  @include media_query(XXL) {
    width: 590px;
    height: 536px;
    left: 66px;
  }
}
// PlayStore Icon

.playStoreWrapper {
  background: $light_cream_2;
  display: flex;
  justify-content: center;
  position: relative;
  height: 220px;
  padding: 0 20px;
  z-index: 6;
  @include media_query(S) {
    height: 170px;
  }
  @include media_query(M) {
    height: 200px;
  }
}

.googlePlayWrapper {
  display: flex;
  padding: 20px;
  background-color: $white;
  flex-direction: column;
  align-content: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  position: relative;
  top: -65px;
  height: 240px;
  @include media_query(S) {
    height: 190px;
  }
  @include media_query(M) {
    padding: 34px;
    height: 215px;
  }
  @include media_query(L) {
    justify-content: space-around;
    height: 100%;
    top: 0px;
    // width: 100%;
  }
  @include media_query(XL) {
    padding: 30px;
  }
  @include media_query(XXL) {
    padding: 60px 40px;
  }
  .description1 {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 29px;
    margin-bottom: 9px;
    color: $blue;

    @include media_query(L) {
      font-size: 24px;
      line-height: 38px;
    }

    @include media_query(XL) {
      font-size: 33px;
      line-height: 45px;
      margin-bottom: 18px;
    }
  }

  .description2 {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: $blue;
    margin-bottom: 12px;

    @include media_query(XL) {
      font-size: 16px;
      line-height: 28px;
    }

    @include media_query(XXL) {
      font-size: 24px;
      line-height: 37px;
      margin-bottom: 30px;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .googlePlayImgWrapper {
      width: 169px;  
      height: 60px;
      @include media_query(L) {
        display: flex;
        align-items: center;
        width: 130px;
        height: 50px;
      }
      @include media_query(XL) {
        width: 200px;
        height: 60px;
      }
      @include media_query(XXL) {
        width: 245px;
        height: 72px;
      }
      a {
        width: 100%;
        //height: 100%;
        img {
          height: 100%;
          width: 100%;
          //object-fit: cover;
        }
      }
    }
    .qrcode {
      display: none;
      position: relative;
      .txt {
        font-weight: 400;
        font-size: 10px;
        line-height: 13px;
        color: $light_gray;
        padding-left: 10px;
        width: 100%;
        @include media_query(XL){
          font-size: 13px;
          line-height: 18px;
        }
        @include media_query(XXL){
          font-size: 18px;
          line-height: 23px;
        }
      }
      @include media_query(L) {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 110px;
      } 
      @include media_query(XL) {
        width: 140px;
      } 
      @include media_query(XXL) {
        width: 197px;
      } 
    }
    .or-option{
      color: #666666;
      font-weight: 400;    
      display: none;
      @include media_query(L) {
        display: flex;
        align-items: center;
        margin: 0 10px 0 5px;
      } 
      @include media_query(XXL) {
        font-size: 21px;
      }
    } 
  } 
}  
// Help Center

.visava-help-container {
  margin: auto;
  padding: 40px 20px;
  @include media_query(M) {
    padding: 40px 30px;
  }
  @include media_query(L) {
    padding: 60px 40px;
  }
  @include media_query(XL) {
    padding: 80px 60px;
  }
  @include media_query(XXL) {
    padding: 60px 180px;
  }
  .help_inner_wrapper {
    .visava-help-label {
      font-weight: 500;
      font-size: 21px;
      line-height: 29px;
      color: $blue;
      margin-bottom: 30px;
      text-align: center;

      @include media_query(L) {
        display: flex;
        justify-content: center;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 50px;
      }
      @include media_query(XL) {
        font-size: 33px;
        line-height: 44px;
      }
      @include media_query(XXL) {
        margin-bottom: 100px;
      }
    }
    .heading {
      font-weight: 500;
      font-size: 22px;
      line-height: 29px;
      align-items: center;
      color: $blue;
      margin-bottom: 10px;

      @include media_query(L) {
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 17px;
      }
      @include media_query(XL) {
        font-size: 33px;
        line-height: 44px;
        margin-bottom: 27px;
      }
    }

    .description {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: $blue;
      margin-bottom: 30px;

      @include media_query(L) {
        font-size: 18px;
        line-height: 24px;
      }
      @include media_query(XL) {
        font-size: 24px;
        line-height: 37px;
      }
      .bold {
        font-weight: 700;
      }
    }
    .visava-steps-wrapper {
      .step-image {
        max-width: 250px;
        margin-bottom: 30px;
        @include media_query(M) {
         max-width: 250px;
        }
        @include media_query(XL) {
          max-width: 325px;
         }
         @include media_query(XXL) {
          max-width: 450px;
         }
      }
    }
    .material-image,.costing-image {
      width: 205px;
      margin-bottom: 30px;

      @include media_query(M) {
        width: 100%;
        max-width: 250px;
      }
      @include media_query(XL) {
        width: 100%;
        width: 325px;
      }
      @include media_query(XXL) {
        width: 100%;
        max-width: 450px;
       }
    }
    .item {
      img {
        height: 100%;
        width: 100%;
      }
      @include media_query(XXL) {
        margin-bottom: 60px;
      }
      &:last-child {
        margin-bottom: 0;
        .material-image,.description {
          margin-bottom: 0;
        }
      }
    }
  }
  .desktop_view {
    display: none;
    @include media_query(L) {
      display: block;
    }
  }
  .mobile_viw {
    @include media_query(L) {
      display: none;
    }
  }
}

// VisawaCarousal

.carousal-container {
  padding: 40px 20px;
  @include media_query(M) {
    padding: 40px 30px;
  }
  .carousal-wrapper {
    margin-bottom: 50px;

    .slick-slider {
      .slick-dots {
        display: flex;
        top: auto;
        bottom: -25px;
        width: 100%;

        li {
          margin: 0px 7px;
          height: 8px;
          width: 8px;

          button {
            height: 8px;
            width: 8px;
            padding: 0;
            &::before {
              content: "";
              background-color: $light_gray_1;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              opacity: 1;
            }
          }
        }

        .slick-active {
          button {
            &::before {
              background: $dragon_green_1;
            }
          }
        }
      }

      .slick-prev,
      .slick-next {
        width: 12px;
        height: 24px;
        background-repeat: no-repeat;
        background-size: contain;
        z-index: 11;
        background-color: transparent;
        top: 63%;
        &::before {
          display: none;
        }
      }

      .slick-prev {
        margin-left: 0px;
        left: 0;
        background-image: url("../../../../src/asset/images/backward-carousal.svg");
      }

      .slick-next {
        margin-right: 0px;
        right: 0;
        background-image: url("../../../../src/asset/images/forward-carousal.svg");
        z-index: 1;
      }

      .slick-disabled {
        filter: invert(90%) sepia(0%) saturate(1251%) hue-rotate(134deg)
          brightness(93%) contrast(119%);
        opacity: 0.4;
      }
    }
  }

  .text-box {
    .visava-web {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      color: $blue;
      margin-bottom: 3px;
      padding-top: 2px;

      @include media_query(L){
        padding-top: 0;
      }
    }
    .offer_tag {
      background-color: $yellow;
      color: $blue;
      width: 120px;
      padding: 11px 10px;
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 5px;
      text-align: center;
    }

    .visava-web-costing {
      // font-family: Metropolis;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 155.4%;
      color: $blue;
      margin-bottom: 100px;
      .original_price {
        padding: 0 2px;
        position: relative;
        &:before {
          position: absolute;
          content: "";
          left: 0;
          top: 40%;
          right: -2px;
          border-top: 2px solid $black;
          border-color: inherit;
          
          -webkit-transform:rotate(166deg);
          -moz-transform:rotate(166deg);
          -ms-transform:rotate(166deg);
          -o-transform:rotate(166deg);
          transform:rotate(166deg);
        }
      }
    }
  }

  .carousal-image-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .carousal-image {
      width: 240px;
      height: 365px;
    }
  }

  .carousal-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .sample-docs {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      height: 40px;
      width: 295px;
      border: 1px solid $dragon_green_1;
      box-sizing: border-box;
      font-weight: 600;
      font-size: 14px;
      color: $dragon_green_1;
      margin-bottom: 20px;
      cursor: pointer;
    }

    .get-started-wrapper {
      display: flex;
      align-content: center;
      justify-content: center;
      background: $dragon_green;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
      border-radius: 6px;
      height: 40px;
      width: 295px;
      flex-wrap: wrap;
      cursor: pointer;
      @include media_query(L) {
        box-shadow: none;
      }

      .get-started {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
        color: $white;
      }

      .arrow-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        .forward-arrow {
          height: 11px;
          width: 8px;
          display: flex;
          margin-left: 10px;
        }
      }
    }
  }
}

.carousal-container-l {
  .slick-slider {
    padding-bottom: 90px;
    padding-top: 60px;
    .slick-dots {
      display: flex;
      top: auto;
      width: 100%;
      bottom: 30px;
      li {
        margin: 0px 8px;
        height: 12px;
        width: 12px;

        button {
          height: 12px;
          width: 12px;
          padding: 0;
          &::before {
            content: "";
            background-color: $light_gray_1;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            opacity: 1;
          }
        }
      }

      .slick-active {
        button {
          &::before {
            background: $dragon_green_1;
          }
        }
      }
    }

    .slick-prev,
    .slick-next {
      width: 12px;
      height: 24px;
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 1;
      background-color: transparent;

      &::before {
        display: none;
      }

      @include media_query(L) {
        height: 50px;
        width: 25px;
      }
    }

    .slick-prev {
      background-image: url("../../../../src/asset/images/backward-carousal.svg");
      left: 40px;
      @include media_query(XL) {
        left: 35px;
      }
      @include media_query(XXL) {
        left: 60px;
      }
    }

    .slick-next {
      background-image: url("../../../../src/asset/images/forward-carousal.svg");
      right: 40px;
      @include media_query(XL) {
      right: 35px;
      }
      @include media_query(XXL) {
        right: 60px;
        }
    }

    .slick-disabled {
      filter: invert(90%) sepia(0%) saturate(1251%) hue-rotate(134deg)
        brightness(93%) contrast(119%);
      opacity: 0.4;
    }
  }

  .carousal-wrapper-l {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    padding: 0 80px;
    @include media_query(XXL) {
      padding: 0 180px;
    }
    .text-container-l {
      display: flex;
      justify-content: center;
      // align-items: center;
      flex-direction: column;
      .offer_tag {
        background-color: $yellow;
        color: $blue;
        width: 232px;
        padding: 10px 20px;
        font-size: 23px;
        line-height: 31px;
        font-weight: 600;
        margin-bottom: 10px;
        text-align: center;
      }
      .text-box {
        .visava-web {
          font-style: normal;
          font-weight: 600;
          font-size: 26px;
          line-height: 36px;
          color: $blue;
          margin-bottom: 16px;
          max-width: 550px;
          @include media_query(XXL) {
            font-size: 33px;
            line-height: 44px;
          }
        }

        .visava-web-costing {
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
          font-size: 18px;
          color: $blue;
          margin-bottom: 40px;
          max-width: 554px;
          .original_price {
            padding: 0 2px;
            position: relative;
            &:before {
              position: absolute;
              content: "";
              left: 0;
              top: 40%;
              right: -2px;
              border-top: 2px solid $black;
              border-color: inherit;
              
              -webkit-transform:rotate(166deg);
              -moz-transform:rotate(166deg);
              -ms-transform:rotate(166deg);
              -o-transform:rotate(166deg);
              transform:rotate(166deg);
            }
          }
          @include media_query(XXL) {
            line-height: 37px;
            font-size: 24px;
          }
        }

        .carousal-buttons {
          display: flex;
          width: 100%;
          justify-content: center;
          flex-direction: column;
          @include media_query(XXL) {
            flex-direction: row;
            justify-content: flex-start;
          }
          .sample-docs {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            height: 54px;
            border: 2px solid $dragon_green_1;
            box-sizing: border-box;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            color: $dragon_green_1;
            margin-bottom: 20px;
            cursor: pointer;
            width: 306px;

            @include media_query(XXL) {
              font-size: 21px;
              line-height: 23px;
              margin-bottom: 0;
            }
          }

          .get-started-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            background: $dragon_green;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
            border-radius: 4px;
            height: 54px;
            flex-wrap: wrap;
            width: 306px;
            cursor: pointer;
            @include media_query(L) {
              box-shadow: none;
            }
            @include media_query(XXL) {
              margin-left: 22px;
            }
            .get-started {
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
              text-transform: uppercase;
              color: $white;

              @include media_query(XXL) {
                font-size: 21px;
                line-height: 28px;
              }
            }

            .arrow-wrapper {
              .forward-arrow {
                display: flex;
                height: 8px;
                width: 15px;
                margin-left: 12px;
                @include media_query(L) {
                  height: 15px;
                  width: 9px;
                }
              }
            }
          }
        }
      }
    }

    .image-container-l {
      min-height: 400px;
      width: 410px;
      display: flex;
      align-items: center;

      .carousal-image {
        height: 100%;
        width: 100%;
      }
    }
  }
}

.features-container {
  background-color: $white;
  margin-bottom: 40px;
  padding: 0 20px;
  @include media_query(M) {
    height: 82px;
    padding: 0 30px;
    margin-bottom: 0;
  }
  @include media_query(M) {
    padding: 0 40px;
  }
  @include media_query(XL) {
    padding: 0 80px;
  }
  @include media_query(XXL) {
    padding: 0 180px;
    height: 86.5px;
  }

  .features-inner-container {
    background-color: $blue;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    position: relative;
    padding: 25px;
    @include media_query(M) {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      padding: 10px;
    }
    @include media_query(L) {
      padding: 25px;
    }  
    @include media_query(XXL) {
      padding: 30px;
    }

    .feature-wrapper {
      color: $white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      @include media_query(M) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .feature-image {
        width: 50px;
        height: 50px;
        margin-bottom: 20px;
        @include media_query(XL) {
          margin-bottom: 8px;
        }
      }

      .feature-title {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 2px;
        @include media_query(M) {
          text-align: center;
        }
        @include media_query(XL) {
          font-size: 24px;
          line-height: 32px;
        }
      }

      .feature-subtitle {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        margin-bottom: 15px;
        @include media_query(M) {
          text-align: center;
          margin-bottom: 0;
        }
        @include media_query(XL) {
          font-size: 16px;
          line-height: 21px;
        }
      }
      &:last-child {
        .feature-subtitle {
        margin-bottom: 0;
      }
      }
    }

    .feature-divider {
      width: 60px;
      height: 1px;
      background-color: $white;
      margin-bottom: 25px;
      @include media_query(M) {
        width: 1px;
        height: 140px;
        background-color: $white;
        margin-bottom: 0px;
      }
      @include media_query(L) {
        height: 110px;
      }
    }
  }
}

// Visava Vendor Registeration

.register-vender-container {
  display: flex;
  justify-content: center;
  padding: 36px 20px;
  background-color: $light_cream_2;
  @include media_query(M) {
    padding: 36px 30px;
  }
  @include media_query(L) {
    padding: 36px 40px;
  }
  @include media_query(XL) {
    padding: 70px 80px;
  }
  .register-vender-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .intrested-vendor {
      font-weight: 500;
      font-size: 22px;
      line-height: 29px;
      display: flex;
      align-items: center;
      color: $blue;
      margin-bottom: 30px;
      letter-spacing: .2px;

      @include media_query(L) {
        font-size: 33px;
        line-height: 44px;
        margin-bottom: 44px;
      }
    }

    .sign-up-button {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      height: 40px;
      width: 280px;
      background: $dragon_green_1;
      box-sizing: border-box;
      font-weight: 600;
      font-size: 14px;
      color: $white;
      cursor: pointer;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);

      @include media_query(XL) {
        width: 272px;
        height: 64px;
        margin: auto;
        font-weight: 600;
        font-size: 24px;
        line-height: 133.4%;
      }
    }
  }
}

// Visava Youtube Video

.visava-video-container {
  height: 200px;
  margin-bottom: 40px;

  @include media_query(L) {
    height: 500px;
    margin-bottom: 60px;
  }

  // .react-player {
  // }
}

// Homes By Visava

.homes-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
  padding: 40px 20px;
  @include media_query(M) {
    padding: 40px 30px;
  }
  @include media_query(L) {
    padding: 60px 0 60px 30px;
  }
  @include media_query(XL) {
    padding: 60px 0 60px 30px;
  }
  .homes-by-visava {
    margin-bottom: 22px;
    font-weight: 500;
    font-size: 22px;
    line-height: 133.4%;
    color: $blue;

    @include media_query(L) {
      margin-bottom: 60px;
      font-size: 33px;
      line-height: 44px;
    }
  }
  // li[class='react-multi-carousel-item react-multi-carousel-item--active '] {
  //   // width: 384px!important;
  // }
  .homes-wrapper {
    width: 290px;
    margin-bottom: 25px;
    @include media_query(L) {
      width: 308px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 0;
    }

    .image-wrapper {
      margin-bottom: 15px;
      height: 195px;
      width: 100%;
      border: 1px solid $blue;
      box-sizing: border-box;
      border-radius: 9px;
      @include media_query(L) {
        padding: 28px 0;  
        width: 308px;
        height: 266px;
        margin-bottom: 40px;
      }
      .home-image {
        height: 100%;
        width: 100%;
      }
    }

    .town-wrapper {
      font-weight: 600;
      font-size: 22px;
      line-height: 133.4%;
      color: $blue;

      @include media_query(L) {
        font-weight: 600;
        font-size: 34px;
        line-height: 133.4%;
      }
    }
  }

  .view-more-wrapper {
    margin-bottom: 50px;
    height: 40px;
    border: 1px solid $dragon_green_1;
    box-sizing: border-box;
    border-radius: 6px;
    color: $dragon_green_1;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  // .react-multi-carousel-item {
  //   margin-right: 80px;
  // }
  .react-multiple-carousel__arrow {
    margin-bottom: 85.22px;
  }
}

// Contact us form

.contact-us-container {
  padding: 40px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include media_query(M){
    padding: 40px 30px;
  }
  @include media_query(L){
    padding: 30px;
  }

  .contact-us-text {
    font-weight: 500;
    font-size: 22px;
    line-height: 133.4%;
    color: $blue;
    margin-bottom: 21px;
    width: 100%;
    @include media_query(M) {
      max-width: 470px;
    }
    @include media_query(L) {
      display: none;
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      margin-bottom: 32px;
    }
  }

  .contact-us-form-wrapper {
    width: 100%;
    @include media_query(M) {
      max-width: 470px;
    }
    .form-wrapper {
      display: flex;
      flex-direction: column;

      .error-text {
        color: $error_orange;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 5px;
      }

      .form-label {
        margin-bottom: 10px;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 128.91%;
        color: $blue;

        @include media_query(L) {
          font-weight: 600;
          font-size: 18px;
          line-height: 128.91%;
          margin-bottom: 5px;
        }
      }
      .form-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
      }
      .form-input {
        // margin-bottom: 30px;
        height: 44px;
        background: $light_cream_1;
        border: 1px solid $gray91;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 0px 17px;

        @include media_query(L) {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
        }

        &.error {
          border-color: $error_orange;
        }
        &::placeholder {
          font-size: 16px;
          font-weight: 400;
          color: #BDBDBD;
        }
      }

      .form-input-desc {
        // margin-bottom: 30px;
        height: 44px;
        background: $light_cream_1;
        border: 1px solid $gray91;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 14px 17px 0;
        resize: none;
        overflow: auto;
        font-weight: 500;
        @include media_query(L) {
          font-size: 16px;
          line-height: 20px;
          height: 100px;
        }

        &.error {
          border-color: $error_orange;
        }
        &::placeholder {
          font-weight: 400;
        }
      }

      .form-submit {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        height: 40px;
        width: 280px;
        background: $dragon_green_1;
        box-sizing: border-box;
        font-weight: 600;
        font-size: 14px;
        color: $white;
        cursor: pointer;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
        margin: auto;

        @include media_query(L) {
          height: 46px;
          width: 100%;
        }
        @include media_query(XL) {
          height: 54px;
          width: 315px;
          margin: 16px auto;
        }
      } 
    }
  }
}
// Get in Touch

.get-in-touch-container {
  background: $blue;
  color: $white;
  padding: 17px 20px;
  @include media_query(M) {
    padding: 30px;
  }
  @include media_query(L) {
    position: relative;
    padding: 30px 40px;
  }
  @include media_query(XL) {
    padding: 60px 80px;
  }

  .contact-us-container-L {
    background-color: $white;
    right: 40px;
    position: absolute;
    width: 44%;
    top: 30px;

    @include media_query(L) {
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      max-width: 460px;
    }
    @include media_query(XL) {
      right: 80px;
      top: 60px;
    }
  }

  .get-in-touch {
    font-weight: 500;
    font-size: 22px;
    line-height: 133.4%;
    margin-bottom: 11px;

    @include media_query(L) {
      font-size: 44px;
      line-height: normal;
      margin-bottom: 26px;
    }
  }

  .firm-name {
    margin-bottom: 11px;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;

    @include media_query(L) {
      font-size: 19px;
      line-height: 19px;
    }
  }

  .location-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 38px;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;

    @include media_query(L) {
      font-size: 19px;
      line-height: 19px;
    }

    .image-wrapper {
      height: 20px;
      padding-right: 5px;
    }

    .location-icon {
      height: 100%;
    }
  }

  .link {
    width: fit-content;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 27px;
    cursor: pointer;
    @include media_query(L) {
      font-size: 19px;
      line-height: 19px;
      margin-bottom: 34px;
    }
  }

  .login {
    padding-bottom: 45px;
    margin-bottom: 0;
  }
  .contact-wrapper {
    padding-top: 24px;
    padding-bottom: 26px;
    @include media_query(L) {
      padding-top: 16px;
    }
  }

  .contact-container {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    text-decoration: none;

    .mobile-icon-wrapper {
      height: 19px;
      width: 12px;
      margin-right: 17px;
      margin-left: 6px;

      @include media_query(L) {
        height: 28px;
        width: 15px;
        // margin-left: 0.3%;
      }

      .mobile-icon {
        width: 100%;
      }
    }

    .email-icon-wrapper {
      height: 12px;
      width: 16px;
      margin-right: 16px;
      margin-left: 6px;

      @include media_query(L) {
        height: 18px;
        width: 24px;
      }

      .email-icon {
        width: 100%;
      }
    }

    .contact-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      color: white;
      text-decoration: none;
      cursor: pointer;

      @include media_query(L) {
        font-size: 19px;
        line-height: 19px;
      }
    }
  }

  .stay-connected {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    margin: 50px 0 18px;

    @include media_query(L) {
      font-size: 21px;
      line-height: 21px;
      margin-bottom: 20px;
    }
  }

  .social-icon-wrapper {
    margin-left: -5px;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 34px;
    @include media_query(L) {
      margin-bottom: 10px;
    }

    .icon {
      margin-right: 20px;
      height: 40px;
      width: 40px;
      cursor: pointer;
    }
  }

  .play-store-wrapper {
    width: 170px;
    height: 64px;
    margin-bottom: 25px;

    @include media_query(L) {
      display: none;
      margin-bottom: 0;
    }

    .play-store {
      height: 100%;
      width: 100%;
    }
  }

  .copyright-container {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    @include media_query(L){
      padding-bottom: 37px;
      margin-top: 10px;
    }

    .copyright-image-wrapper {
      margin-right: 4px;
      display: flex;

      .copyright-image {
        height: 19px;
        width: 19px;
        min-width: 19px;
      }
    }

    .copyright-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 128.91%;

      @include media_query(L) {
        font-size: 18px; 
      }
    }

    .play-store-wrapper {
      display: none;

      @include media_query(L) {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
      }
      @include media_query(XL) {
        width: 218px;
        height: 65px;
      }

      .play-store {
        height: 100%;
        width: 100%;
      }
    }
  }
}
