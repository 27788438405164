@import '../../../asset/styles/scss/common/colors';
@import '../../../asset/styles/scss/common/media-queries';
.budget-container {
  overflow-y: auto;
  height: 100vh;
  @include media_query(L) {
    height: auto;
  }
  .banner-image-container {
    display: flex;
    justify-content: center;
    width: 100%;
    background: $blue;
    padding: 15px 30px;
    position: relative;
    height: 60%;
    @include media_query(XL) {
      padding: 50px; 
      height: auto;
    }
    .info_container {
      max-width: 460px;
      position: relative;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      @include media_query(XL) {
        max-width: 600px;
      }
      .txt {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: $dragon_green;
        margin-bottom: 33px;
        @include media_query(L) {
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 44px;
        }
      }
      .img_area {
        display: flex;
        align-items: center;
        height: 103px;
        width: 48px;
        margin-bottom: 13px;
        img {
          width: 100%;
          height: 100%;
        }
        @include media_query(L) {
          height: 216px;
          width: 100px;
          margin-bottom: 16px;
        }
      }
      .title_area {
        color: $white;
        text-align: center;
        .title {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 20px;
          font-weight: 700;
          text-transform: uppercase;
          @include media_query(L) {
            font-size: 33px;
            line-height: 41px;
          }
        }
        .sub_title {
          font-size: 14px;
          line-height: 18px;
          font-weight: 500;
          margin-bottom: 18px;
          @include media_query(L) {
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 36px;
          }
        }
      }
      .btn_area {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 26px;
      }
    }
  }
  .or_container {
    margin: auto;
    margin-top: -30px;
    width: 60px;
    height: 60px;
    display: flex;
    padding: 14px;
    justify-content: center;
    background-color: $white;
    color: $blue;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    border-radius: 50%;
    position: relative;
    z-index: 1;
    @include media_query(L) {
      width: 122px;
      height: 122px;
      font-size: 32px;
      padding: 28px;
      line-height: 41px;
      margin-top: -50px;
    }
  }
  .bottom_info_container {
    padding: 15px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 35%;
    @include media_query(XL) {
      padding: 70px; 
      height: auto;
    }
    .description {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: $blue;
      max-width: 820px;
      text-align: center;
      margin-bottom: 20px;
      @include media_query(L) {
        font-size: 24px;
        line-height: 32px;
      }
      .budget {
        color: $dragon_green;
      }
    }
    // .continue {
    //   @include media_query(L) {
    //     width: 346px;
    //   }
    // }
  }
}
