@import '../../../asset/styles/scss/common/colors';
@import '../../../asset/styles/scss/common/media-queries';
.payment_container {
  background: linear-gradient(0.04deg, #00B3A9 -24.39%, #00C6BB 100.13%);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $white;
  padding: 30px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1111;
  .image {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 26px;
    img {
      width: 100%;
      height: 100%;
    }
    @include media_query(L) {
      width: 80px;
      height: 80px;
    }
  }
  .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 133.4%;
    text-transform: uppercase;
    margin-bottom: 16px;
    text-align: center;
    color: $white;
  }
  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 133.4%;
    margin-bottom: 26px;
    color: $white;
    max-width: 300px;
    text-align: center;
    @include media_query(L) {
      max-width: 500px;
      font-size: 18px;
    }
  }
  .button {
    border: 1px solid $white;
    border-radius: 6px;
    width: 233px;
    height: 40px;
    text-align: center;
    padding: 13px;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    text-transform: uppercase;
    cursor: pointer;
  }
}
.close_icon_wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  //width: 100%;
  padding: 20px 20px 10px;
  position: absolute;
  top: 0%;
  left: 0;
  right: 0;
  .close_icon {
    background-image: url("../../../asset/images/close_icon_white.svg");
    height: 30px;
    width: 30px;
    background-repeat: no-repeat;
    background-size: contain;          
    cursor: pointer;
  }
}
.payment_processing_container {
  background-color: $dragon_green;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
  .image {
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    img {
      width: 100%;
      height: 100%;
    }
    @include media_query(L) {
      width: 290px;
      height: 290px;
      margin-bottom: 80px;
    }
  }
  .description {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: $white;
    text-transform: uppercase;
    max-width: 400px;
    text-align: center;
    @include media_query(L) {
      max-width: 500px;
      font-size: 24px;
      line-height: 40px;
    }
  }
}