.share-container {
  position: relative;
  height: 100%;

  .share-wrapper {
    position: absolute;
    height: 42%;
    bottom: 0;
    width: 100%;
    background: $white;
    padding-bottom: 20px;

    .text-container {
      height: 20px;
      margin-bottom: 34px;

      .text-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
      }
    }

    .share-text {
      margin-top: 16px;
      margin-left: 16px;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      color: #666666;
      margin-bottom: 30px;
    }

    .share-icon-wrapper {
      display: flex;
      justify-content: space-between;
      margin-left: 36px;
      margin-right: 36px;

      .image-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .image {
          height: 40px;
          width: 40px;

          @include media_query(L){
            height: 49px;
            width: 49px;
          }
        }
      }
    }
  }
}