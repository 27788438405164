//## colors for use across.

$white: #fff;
$black: #000;
$dragon_green: #00B0A6;
$dragon_green_1: #07AFA6;
$blue: #192643;
$error_orange: #F4511E;
$light_cream_1: #F6F6F6;
$light_cream_2: #F5F5F5;
$gray91: #E8E8E8;
$light_gray: #666666;
$light_gray_2: #F7F7F7;
$yellow: #FFD54F;
$border_color: #ADB7CD;
$placeholder: #B3B8C5;
$light_gray_1: #BEC2C4;
$color_placeholder: #BDBDBD;
$border_color_1 : #E5E5E5;
$color_gray: #B9B9B9;
