// Shared Header
.header-container-1 {
  height: 65px;
  justify-content: space-between;
  display: flex;
  position: fixed;
  z-index: 1100;
  top: 0;
  width: 100%;
  background: $white;
  padding: 0 10px;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
  @include media_query(L) {
    height: 104px;
    padding: 0 30px;
  }
}
.header-container-2 {
  display: flex;
  height: 65px;
  justify-content: space-between;
  z-index: 1;
  top: 0;
  width: 100%;
  background: $white;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
  @include media_query(L) {
    height: 80px;
  }
}
.logoContainer {
  width: 50px;
  height: 40px;
  cursor: pointer;
}
.logo {
  width: 100%;
  height: 100%;
}
.langLogo {
  height: 15px;
  width: 15px;
  margin-right: 5px;
  padding-bottom: 2px;
}
.headerLinksContainer {
  display: flex;
  align-items: center;
  text-align: center;
  color: $dragon_green;
  height: 100%;
  .header-link-wrapper {
    display: flex;
    justify-content: space-evenly;
    height: 100%;
    .headerLinks {
      padding: 0 14px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      padding-top: 2px;
      &.active {
        background-color: $dragon_green;
        color: $white;
      }
    }
    a {
      text-decoration: none;
      color: $dragon_green;
    }
  }
  .offer_tag_img {
    position: absolute;
    top: -30px;
    right: 20px;
    width: 44px;
    height: 41px;
    z-index: 9;
    @include media_query(L) {
      width: 50px;
      height: 50px;
    }
    @include media_query(XL) {
      top: -18px;
      right: 18px;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  .offer_tag_img_hi_mi {
    position: absolute;
    top: 4px;
    right: 155px;
    width: 44px;
    height: 41px;
    z-index: 1;
    @include media_query(L) {
      width: 50px;
      height: 50px;
      top: -30px;
      right: auto;
      left: 36px;
    }
    @include media_query(XL) {
      width: 44px;
      height: 41px;
      left: 60px;
      top: -14px;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}
.playStore-container {
  width: 130px;
  height: 50px;
  @include media_query(XL) {
    width: 150px;
    height: 60px;
  }
  a {
    width: 100%;
    height: 100%;
  }
  .playStoreImg {
    height: 100%;
    width: 100%;
  }
}
.loginButton {
  margin-left: 17px;
  font-size: 12px;
  line-height: 12px;
  color: $white;
  border: none;
  width: 82px;
  height: 28px;
  border-radius: 8px;
  cursor: pointer;
  background-color: $dragon_green;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  text-transform: uppercase;
  @include media_query(L) {
    width: 145px;
    height: 44px;
    font-size: 18px;
    line-height: 18px;
  }
}
.icon-wrapper {
  width: 23px;
  height: 23px;
  margin-left: 18px;
  @include media_query(L) {
    margin-left: 30px;
  }
  .side-menu {
    margin-right: 14px;
    width: 100%;
    height: 100%;
  }
  .close-icon {
    width: 100%;
    height: 100%;
  }
}
.logoWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  .brand_name {
    font-weight: 500;
    margin-top: 3px;
    letter-spacing: 2px;
    font-size: 12px;
    color: $blue;
    text-align: center;
    display: flex;
    .beta-txt {
      background-color: rgba($dragon_green, 0.4);
      border-radius: 8px;
      color: $blue;
      font-size: 9px;
      line-height: 10px;
      font-weight: 400;
      letter-spacing: .5px;
      margin-left: 6px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      height: 12px;
      width: 34px;
    }
  }
  .design-house-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    color: $blue;
    @include media_query(L) {
      color: $dragon_green_1;
      text-transform: uppercase;
      text-align: center;
      width: 100%;
    }
    @include media_query(XL) {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
    }
  } 
}
// Side Menu Options
.sideMenu-options {
  position: fixed;
  top: 0;
  z-index: 28;
  height: 100%;
  width: 100%;
  margin-top: 64px;
  padding: 25px 20px;
  color: $blue;
  background-color: $white;
  overflow: hidden;
  z-index: 1009;
  @include media_query(L) {
    padding-top: 30px;
    margin-top: 104px;
  }
  @include media_query(XL) {
    padding: 100px;
  }
  .options {
    margin-bottom: 30px;
    text-decoration: none;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    //text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media_query(L) {
      font-size: 20px;
      line-height: 30px;
    }
    @include media_query(XL) {
      font-size: 34px;
      line-height: 45px;
    }
    .close_icon {
      background-image: url("../../../asset/images/close_icon_black.png");
      height: 30px;
      width: 30px;
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
      @include media_query(XL) {
        height: 44px;
        width: 44px;
      }
    }
  }
  .lang_icon {
    height: 15px;
    width: 15px;
    margin-right: 5px;
    margin-bottom: 2px;
    @include media_query(XL) {
      width: 28px;
      height: 28px;
      margin-right: 12px;
    }
     img {
       width: 100%;
       height: 100%;
     }
  }
  a {
    text-decoration: none;
  }
}
.opt-link {
  cursor: pointer;
}
.languages {
  @include media_query(L) {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
.extra-space {
  margin-right: 10%;
  @include media_query(L) {
    padding-bottom: 70px;
  }
}
.hidecomponent {
  display: none;
}
.get-document-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $dragon_green_1;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
  color: $white;
  height: 40px;
  width: 233px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  position: relative;
  overflow: hidden;
  @include media_query(XL) {
    font-size: 18px;
  }
  .original_price {
    text-decoration: line-through;
    padding: 0 2px;
  }  
  &::before {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    content: '';
    border-top: 12px solid $yellow;
    border-left: 12px solid $yellow;
    border-bottom: 12px solid transparent;
    border-right: 12px solid transparent;
    @include media_query(XL) {
      border-top: 15px solid $yellow;
      border-left: 15px solid $yellow;
      border-bottom: 15px solid transparent;
      border-right: 15px solid transparent;
    }
  }
  @include media_query(XL) {
    height: 64px;
    width: 309px;
  }
  .get-document-submit {
    background: $dragon_green_1;
    border: none;
    color: $white; 
    font-size: 14px;
    font-weight: 600;
    @include media_query(XL) {
      font-size: 18px;
      line-height: 24px;
    }
  }
}
.design-header-center {
  @include media_query(L){
    padding-left: 25%;
  }
}