.user-profile {
  .image-container {
    margin-bottom: 45px;
    background-color: $blue;
    padding: 25px;
    position: relative;

    .edit-wrapper {
      display: flex;
      align-items: center;
      position: absolute;
      right: 15px;
      top: 15px;

      .edit-image-wrapper {
        margin-right: 5px;
        margin-bottom: 3px;
        height: 12px;
        width: 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 100%;
          width: 100%;
        }
      }

      .edit-text {
        color: $white;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }
    }
    .profile-image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-bottom: 5px;

      .profile-image-wrapper {
        background-color: $white;
        border: 2px solid $dragon_green;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 85px;
        width: 85px;
        border-radius: 50%;
        margin-bottom: 10px;
        position: relative;
        .user-image {
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .camera-image-wrapper {
          position: absolute;
          height: 25px;
          width: 25px;
          right: 0;
          bottom: 0;

          .camera-image {
            height: 100%;
            width: 100%;
          }
        }
      }


      .profile-name-number {
        color: $white;
        font-size: 14px;
        font-weight: 600;
        line-height: 19px;

        &.spacing1 {
          font-weight: 300;
          margin-bottom: 0px;
        }
        &.spacing2 {
          margin-top: 0px;
          font-weight: 300;
          margin-bottom: 0px;
          color: $dragon_green;
          //text-decoration: underline;
        }

        .edit-name-input{
          width: 50%;
          margin: auto;

          @include media_query(L){
            width: auto;
          }
        }
      }
      .update-name {
        border: none; 
        border-bottom:2px solid $dragon_green;
        color: $white;
        background-color: transparent;
        font-size: 14px;
        font-weight: 600;
        line-height: 15px;
        min-width:80px;
        margin: 5px;
      }
    }
  }

  .link-container {
    display: flex;
    align-items: center;
    padding: 15px 0 15px 35px;
    @include media_query(XL) {
      padding: 23px 0 23px 35px;
    }
 

    .icon-wrapper {
      width: 40px;
      height: 40px;
      margin-right: 21px;

      .icon {
        height: 100%;
        width: 100%;
      }
    }

    .description {
      font-size: 14px;
      line-height: 21px;
      font-weight: 500;
      color: $blue;

      .email {
        font-size: 16px;
        line-height: 16px;
        font-weight: 600;
        color: $dragon_green;
        text-decoration: underline;
      }
    }
  }

  .bottom-line {
    margin-left: 35px;
    opacity: 0.2;
    border-bottom: 1px solid $blue;
  }

}

.crop-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(53, 52, 52, 0.4);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; 
  z-index: 2;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh; 
  width: 100vw;
  .inner_wrapper {
    padding: 30px;
    max-height: calc( 100vh - 100px );
  }
  .crop-inner-container { 
    background-color: rgb(255, 255, 255);
    margin-bottom: 10px;
    max-width: 600px;
    max-height: calc(100vh - 160px);
    overflow-y: auto;
  }
  .btn-area {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
    .crop-button{ 
      background-color: $dragon_green_1;
      border-radius: 6px; 
      color: $white;
      font-size: 14px;
      font-weight: 500; 
      padding: 10px;
      margin-right: 20px;
      width: fit-content; 
      height: 40px;
      border: none;
      box-shadow: none;
      @include media_query(L) {
        font-size: 16px;
      }
    }
    .cancel-button{ 
      background-color: $white;
      border-radius: 6px; 
      border: 1px solid $dragon_green_1;
      color: $dragon_green_1;
      font-size: 14px;
      font-weight: 500; 
      padding: 10px;
      width: fit-content; 
      height: 40px;
      box-shadow: none;
      @include media_query(L) {
        font-size: 16px;
      }
    }
  }
}
