.logo-with-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 550;
  margin-top: 20px;
  margin-bottom: 20px;
  @include media_query(M) {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  @include media_query(L) {
    margin-top: 0;
  }
  .image-wrapper {
    height: 56px;
    width: 64px;

    .image {
      height: 100%;
      width: 100%;
    }
  }
  .logo-link {
    margin-left: 32px;
  }
  .visava-logo-text {
    color: $blue;
    margin-bottom: 10px;
    letter-spacing: 2px;
    text-align: center;
    display: flex;
    align-items: center;
    .beta-txt {
      background-color: rgba($dragon_green, 0.4);
      border-radius: 8px;
      color: $blue;
      font-size: 9px;
      line-height: 12px;
      font-weight: 400;
      letter-spacing: .5px;
      margin-left: 4px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      height: 14px;
      width: 34px;
    }
  }
  a {
    text-decoration: none;
  }
  .title {
    font-size: 18px;
    color: $dragon_green_1
  }
}