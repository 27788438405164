.design-my-house-container-location{
    display: flex;
    flex-direction: column;
    width: 100%;
    //padding: 15px 0;
    box-sizing: border-box;
    .confirm-location-container {
      padding: 15px 20px 30px 20px;

      @include media_query(XL) {
        padding: 25px 60px 40px 60px;
      }
  
      .location-name-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
  
        .image-wrapper {
          height: 17px;
          width: 16px;
          min-width: 16px;
          margin-right: 6px;
  
          .image {
            height: 100%;
            width: 100%;
            // object-fit: contain;
          }
        }
  
        .location-name {
          font-size: 18px;
          font-weight: 600;
          color: $blue;
          
        }
      }
  
      .location-detail {
        font-size: 12px;
        font-weight: 400;
        color: $blue;
        margin-bottom: 20px;
        padding-left: 23px;
      }

      .confirm-location-wrapper-change {
        color: $white;
        border: 1px solid $border_color;
        background-color: $white;
        color: $dragon_green;
        height: 22px;
        width: 65px;
        // margin-left: 46px;
        // margin-right: 54px;
        padding: 3px 10px;
        font-size: 12px;
        font-weight: 400;
        box-sizing: border-box;
        border-radius: 3px;
        margin-left: 5px;
        @include media_query(XL) {
          height: 36px;
          width: 85px;
          padding: 8px 12px;
          font-size: 16px;
          margin-left: 15px;
        }
      }
  
      .confirm-location-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        background-color: $dragon_green;
        height: 40px;
        font-size: 14px;
        font-weight: 600;
        border-radius: 8px;
        width: 235px;
        margin: auto;

        @include media_query(L) {
            width: 200px;
            font-size: 16px;
        }
      }
  
    }
  
    .google-map {
      // @include media_query(XL) {
      //   padding: 0 60px 0 60px;
      // }
      .google-map-container {
        display: flex;
        position: relative;
        //margin-bottom: 10px;
        padding: 15px;
        -webkit-box-shadow: 0px 3px 5px 2px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 3px 5px 2px rgba(0,0,0,0.75);
        box-shadow: 0px 7px 14px -12px rgba(0,0,0,0.35);
        @include media_query(L) {
            padding: 20px 40px;
        }
  
        .back-btn-container {
          height: 38px;
          width: 16px;
          display: flex;
          align-items: center;
          margin-right: 16px;
  
          .back-btn {
            height: 16px;
            width: 100%;
          }
        }
        .search_icon {
          background-image: url('../../../asset//images/search_icon.svg');
          background-size: contain;
          background-repeat: no-repeat;
          height: 20px;
          width: 20px;
          position: absolute;
          left: 12px;
          top: 0;
          bottom: 0;
          margin: auto;
        }
  
        .search-location {
          height: 38px;
          background: $light_cream_2;
          border: 1px solid $gray91;
          box-sizing: border-box;
          width: 100%;
          border-radius: 8px;
          padding-left: 38px;
        }
        .autocomplete-dropdown-container {
          //margin-top: 25px;
          position: absolute;
          top: 140px;
          left: 0;
          right: 0;
          z-index: 10;
          background-color: $white;
          width: 100%;
          padding: 0 15px 0 57px;
          @include media_query(L) {
            top: 100%;
            padding: 0 40px 0 77px;
          }
          .search-title {
            padding: 20px 0 8px;
            font-size: 12px;
            line-height: 133.4%;
            color: $light_gray;
            @include media_query(L) {
              font-size: 14px;
            }
          }
          .suggestion-item {
            position: relative;
            padding: 20px 0;
            border-bottom: 1px solid $border_color;
            font-size: 14px;
            line-height: 19px;
            font-weight: 600;
            @include media_query(L) {
              font-size: 16px;
              line-height: 21px;
            }
            .location_icon_blue {
              background-image: url('../../../asset/images/location_icon_blue.svg');
              width: 12px;
              height: 17px;
              background-repeat: no-repeat;
              background-size: 100%;
              position: absolute;
              left: -35px;
            }
            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
  
      .current-location-container {
        display: flex;
        margin-bottom: 10px;
        padding: 24px 15px 0;
        .cur-loc-img-container {
          height: 35px;
          width: 35px;
          margin-right: 15px;
          display: flex;
          align-items: center;
  
          .img-wrapper {
            width: 100%;
            height: 100%;
          }
        }
  
        .current-location-wrapper {
          width: 100%;
          border-bottom: 1px solid $black;
          .current-location {
            font-size: 14px;
            line-height: 19px;
            font-weight: 600;
            color: $blue;
            margin-bottom: 3px;
          }
  
          .using-gps {
            font-size: 14px;
            line-height: 19px;
            font-weight: 400;
            color: $light_gray;
            margin-bottom: 9px;
          }
        }
        @include media_query(L) {
          display: none;
        }
      }
    }
  
    .image-container {
      height: 100%;
      width: 100%;
      margin-bottom: 15px;
  
      .image-wrapper {
        height: 100%;
        width: 100%;
      }
    }
}