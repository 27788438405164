.continue-design-house {
  width: 100%;
  height: 54px;
  background: #07afa6;
  display: flex;
  align-items: center;
  position: relative;

  .continue-text {
    width: fit-content;
    margin: auto;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;

    text-align: center;
    text-transform: uppercase;

    color: #ffffff;
    letter-spacing: 0.025em;
  }
  &::before {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    content: '';
    border-top: 12px solid $yellow;
    border-left: 12px solid $yellow;
    border-bottom: 12px solid transparent;
    border-right: 12px solid transparent;
    @include media_query(XL) {
      border-top: 18px solid $yellow;
      border-left: 18px solid $yellow;
      border-bottom: 18px solid transparent;
      border-right: 18px solid transparent;
    }
  }
}

.houses-container {
  padding-top: 30px;
  .houses-wrapper {
    margin-left: 20px;
    margin-right: 20px;
    .house-count-wrapper {
      .count-area {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;
        .house-count {
          width: 26px;
          height: 26px;
          background-color: $dragon_green;
          border-radius: 50%;
          margin-right: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 6px;
          border: 1px solid $dragon_green;
          &.disabled {
            background-color: #CDCDCD;
            border: 1px solid #A6A6A6;
            position: relative;
            &:before {
              position: absolute;
              content: "";
              left: -1px;
              top: 45%;
              right: 0px;
              border-top: 1px solid #A6A6A6;
              border-color: inherit;
              
              -webkit-transform:rotate(130deg);
              -moz-transform:rotate(130deg);
              -ms-transform:rotate(130deg);
              -o-transform:rotate(130deg);
              transform:rotate(130deg);
            }
          }
          @include media_query(L) {
            width: 32px;
            height: 32px;
            margin-right: 12px;
          }
        }
        @include media_query(L) {
          margin-bottom: 20px;
        }
      }
      .details {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: $blue;
        margin-bottom: 10px;
        @include media_query(L) {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 20px;
        }
      }
    }
    .save-more-wrapper {
      background-color: $blue;
      height: 90px;
      display: flex;
      align-items: center;

      .image-wrapper {
        width: 10%;
        margin-right: 2%;
        margin-left: 10px;

        @include media_query(L) {
          width: 4%;
          margin-right: 6px;
        }
      }

      .text {
        width: 80%;
        color: $white;
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
        @include media_query(L) {
          font-size: 18px;
          line-height: 21px;
        }
        .read-more {
          color: $dragon_green;
          text-decoration: underline;
        }
      }
    }

    .my-houses {
      font-size: 18px;
      font-weight: 600;
      color: $blue;
      margin-bottom: 13px;
    }

    .houses-card-container {
      background: #ffffff;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
      height: 70px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .houses-card-wrappper {
        display: flex;
        width: 100%;

        .image-wrapper {
          margin-right: 20px;
          margin-left: 20px;
          display: flex;
          justify-content: flex-start;

          .image {
            height: 36px;
            width: 36px;
          }
        }

        .house-name-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 90%;
          color: $blue;

          .text {
            font-size: 15px;
            font-weight: 600;
            text-transform: uppercase;
          }

          .date {
            font-size: 12px;
            font-weight: 400;
          }
        }

        .edit-image-wrapper {
          display: flex;
          justify-content: flex-end;
          margin-right: 20px;

          .edit-image {
            width: 20px;
          }

          // .edit-image1 {
          //   width: 100px;
          // }
        }

        .proceed-forward {
          width: 8%;
          display: flex;
          justify-content: flex-end;
          margin-right: 4%;

          .forward-image {
            width: 10px;
          }
        }
      }
    }
    .house-card{
      background: #ffffff;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
      margin-bottom: 20px;
      padding: 15px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .left-area {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        @include media_query(L) {
          flex-direction: row;
        }
        .top-area {
          display: flex;
          align-items: flex-start;
          margin-bottom: 15px;
          @include media_query(L) {
            margin-bottom: 0px;
            margin-right: 30px;
          }
          .house-icon-area {
            margin-right: 18px;
            width: 36px;
            height: 36px;
            background-color: $dragon_green;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
          }
          .house-name {
            max-width: 220px;
            @include media_query(L) {
              max-width: 500px;
            }
            .name {
              font-weight: 600;
              font-size: 14px;
              line-height: 128.91%;
              word-break: break-all;
              color: $blue;
              @include media_query(L) {
                font-size: 16px;
                line-height: 133%;
              }
            }
            .date {
              font-weight: 400;
              font-size: 12px;
              line-height: 128.91%;
              color: #666666;
              @include media_query(L) {
                font-size: 14px;
                line-height: 133%;
              }
            }
          }
        }
        .bottom-area {
          .house-status {
            border-radius: 6px;
            padding: 4px 8px;
            font-weight: 400;
            font-size: 12px;
            line-height: 128.91%;
            @include media_query(L) {
              font-size: 14px;
              line-height: 133%;
            }
            &.purchased {
              color: $dragon_green;
              background-color: rgba($dragon_green, 0.2);
              width: 185px;
              @include media_query(L) {
                width: 215px;
              }
            }
            &.in-progress {
              color: #F5BE00;
              background-color: rgba(#F5BE00, 0.2);
              width: 165px;
              @include media_query(L) {
                width: 190px;
              }
            }
          }
        }
      }
      .edit-icon {
        width: 12px;
        height: 12px;
        @include media_query(L) {
          width: 18px;
          height: 18px;
          margin-right: 2.1%;
          margin-top: 0.9%;
        }
        @include media_query(XL) {
          margin-right: 2.5%;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      &.display-center {
        align-items: center;
        .left-area {
          align-items: center;
          .top-area {
            align-items: center;
            margin-bottom: 0;
          }
        }  
      }
    }  
  }
}
