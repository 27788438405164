.faq-container {
  padding: 20px 40px;
  color: $blue;

  .faq-text {
    font-size: 18px;
    line-height: 23px;
    font-weight: 600;
    margin-bottom: 15px;
    padding-top: 19px;
  }

  .faq-wrapper {
    height: fit-content;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;

    .faq-combined-wrapper {
      width: 100%;
      display: flex;
      padding: 10px 14px;
      @include media_query(L) {
        padding: 14px 20px;
      }
      .text {
        width: 100%;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        cursor: pointer;
      }

      .icon-image-wrapper {
        width: 18px;
        height: 9px;
        margin-left: 58px;

        .icon {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
  .faq-description-wrapper {
    display: flex;
    align-items: center;
    border-top: 1px solid $border_color_1;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 10px 14px;
    width: 100%;
    @include media_query(L) {
      padding: 14px 20px;
    }
    .faq-description-text {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
    }
  }
}