.visava-btn {
  padding: 11px 32px;
  line-height: 18px;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  color: $white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: inline-block;
  //max-width: 311px;
  min-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: $dragon_green;
  width: 233px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include media_query(XL) {
    width: 272px;
    height: 64px;
    font-size: 24px;
  }
  @include media_query(XXL) {
    width: 346px;
  }
  &.trasparent {
    background-color: transparent;
    border-width: 2px;
    border-style: solid;
    padding: 9px 30px;
  }

  &.full-width {
    width: 100%;
  }
}