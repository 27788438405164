.details-container {
  display: flex;
  flex-direction: column;

  @include media_query(L) {
    //height: 100vh;
    width: 100%;
    flex-direction: row;
  }
  .max-house-limit-msg {
    padding: 0 38px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid $border_color;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    text-align: center;
    @include media_query(L) {
      padding: 0;
      border-bottom: 0;
      font-size: 21px;
      line-height: 28px;
    }
  }
  .image-wrapper-3d {
    height: 320px;
    width: 100%;
    position: relative;
    .image {
      height: 100%;
      width: 100%;
    }
    .tap_to_touch_overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .4);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }
  }
  .detail-wrapper-l {
    display: flex;
    width: 100%;
    height: calc(100vh - 104px);

    .section-1 {
      //width: 25%;
      width: 313px;

      .sub-section-1 {
        height: calc(100% - 155px);
        overflow-y: auto;
        .house-details-l {
          width: 100%;
          //height: 20%;
          padding: 20px 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border-bottom: 1px solid #bec2c4;

          .image-wrapper {
            height: 25px;
            width: 25px;
            margin-bottom: 5px;
            margin-right: 5%;
            margin-left: 10%;

            .image {
              height: 100%;
              width: 100%;
            }
          }

          .text {
            font-size: 16px;
            font-weight: 400;
            line-height: 13px;
            color: $blue;
            text-transform: uppercase;

            @include media_query(L) {
              font-size: 18px;
              line-height: 133.4%;
            }
          }
        }
        .edit-house-l {
          width: 100%;
          //height: 20%;
          padding: 20px 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border-bottom: 1px solid #bec2c4;

          .image-wrapper {
            height: 25px;
            width: 25px;
            margin-bottom: 5px;
            margin-right: 5%;
            margin-left: 10%;

            .image {
              height: 100%;
              width: 100%;
            }
          }

          .text {
            font-size: 16px;
            font-weight: 400;
            line-height: 13px;
            color: $blue;
            text-transform: uppercase;

            @include media_query(L) {
              font-size: 18px;
              line-height: 133.4%;
            }
          }
        }

        .full-screen-l {
          width: 100%;
          padding: 20px 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border-bottom: 1px solid #bec2c4;

          .image-wrapper {
            height: 25px;
            width: 25px;
            margin-bottom: 5px;
            margin-right: 5%;
            margin-left: 10%;

            .image {
              height: 100%;
              width: 100%;
            }
          }

          .text {
            font-size: 16px;
            font-weight: 400;
            line-height: 13px;
            color: $blue;
            text-transform: uppercase;

            @include media_query(L) {
              font-size: 18px;
              line-height: 133.4%;
            }
          }
        }

        .show-roof-l {
          width: 100%;
          padding: 20px 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border-bottom: 1px solid #bec2c4;

          .image-wrapper {
            height: 25px;
            width: 25px;
            margin-bottom: 5px;
            margin-right: 5%;
            margin-left: 10%;

            .image {
              height: 100%;
              width: 100%;
            }
          }

          .text {
            font-size: 16px;
            font-weight: 400;
            line-height: 13px;
            color: $blue;
            text-transform: uppercase;

            @include media_query(L) {
              font-size: 18px;
              line-height: 133.4%;
            }
          }
        }

        .share-design-l {
          width: 100%;
          padding: 20px 0;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          border-bottom: 1px solid #bec2c4;
          .share-top-section {
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 10px;
          }
          .image-wrapper {
            height: 25px;
            width: 25px;
            margin-bottom: 5px;
            margin-right: 5%;
            margin-left: 10%;

            .image {
              height: 100%;
              width: 100%;
            }
          }

          .text {
            font-size: 16px;
            font-weight: 400;
            line-height: 13px;
            color: $blue;
            text-transform: uppercase;

            @include media_query(L) {
              font-size: 18px;
              line-height: 133.4%;
            }
          }

          // .share-design-wrapper {
          //   display: flex;
          //   justify-content: flex-start;
          //   align-items: center;
          //   height: 20%;
          //   width: 100%;
          // }

          .share-platform {
            height: 50%;
            width: 100%;
            display: flex;
            margin-left: 10%;

            .share-image-wrapper {
              width: 13%;
              display: flex;
              align-items: center;
              margin-left: 5%;

              .share-image {
                height: 25px;
                width: 25px;
              }
            }
          }
        }
        .sample-btn-area {
          padding: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          .btn {
            background: $white;
            border: 1px solid $dragon_green;
            padding: 23px 15px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: 18px;
            line-height: 18px;
            font-weight: 600;
            color: $dragon_green;
          }
        }
      }

      // .sub-section-2 {
      //   height: 50%;

      //   @include media_query(L) {
      //     height: 40%;
      //   }
      // }

      .sub-section-3 {
        height: 198px;
        background-color: $blue;
        display: flex;
        flex-direction: column;
        padding: 20px;
        @include media_query(XL) {
          height: 155px;
        }

        .image-container {
          display: flex;
          justify-content: center;

          .image-wrapper {
            height: 42px;
            width: 42px;

            .image {
              height: 100%;
              width: 100%;
            }
          }
        }

        .text {
          color: $white;

          @include media_query(L) {
            font-weight: 500;
            font-size: 18px;
            line-height: 117.1%;
          }
        }

        .read-more {
          text-decoration: underline;
          color: $dragon_green;

          @include media_query(L) {
            font-weight: 500;
            font-size: 18px;
            line-height: 117.1%;
          }
        }
      }
    }

    .section-2 {
      //height: 100%;
      width: calc(100% - 313px);

      .d-modal-container {
        border: 1px solid #bec2c4;
        height: calc(100% - 155px);
        .d-image-wrapper {
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          .d-image {
            height: 99%;
          }
        }
      }

      .document-container {
        height: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: #ffffff;
        box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.14);

        @include media_query(L) {
          // height: 15%;
          padding: 20px 0;
          height: 198px;
        }
        @include media_query(XL) {
          flex-direction: row;
          padding: 40px 0;
          height: 155px;
        }
        .sample-documents {
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          font-size: 14px;
          line-height: 14px;
          color: #07afa6;
          height: 40px;
          margin: 0 auto;
          border-radius: 6px;
          border: solid 1px #07afa6;
          box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
          box-sizing: border-box;

          @include media_query(L) {
            height: 64px;
            width: 307px;
            font-weight: 600;
            font-size: 18px;
            line-height: 18px;
            margin-bottom: 20px;
          }
          @include media_query(XL) {
            margin: 0;
            margin-right: 46px;
          }
        }

        .image-wrapper {
          height: 50px;
          width: 35%;

          .image {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
  .option-container {
    display: flex;
    justify-content: space-around;
    height: 50px;
    align-items: center;
    margin-bottom: 18px;
    background-color: $light_gray_2;
    border-top: 1px solid $border_color;
    border-bottom: 1px solid $border_color;
    width: 100%;
    .option-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 50%;

      .image-wrapper {
        height: 20px;
        width: 20px;
        margin-right: 8px;

        .image {
          height: 100%;
          width: 100%;
        }
      }

      .text {
        font-size: 12px;
        font-weight: 600;
        line-height: 13px;
        color: $blue;
        text-transform: uppercase;
      }
    }

    .vertical-line {
      height: 100%;
      width: 1px;
      background-color: $border_color;
    }
  }
  .doc_btn_container {
    position: relative;
    width: 233px;
    margin: auto;
    margin-top: 35px;
  }
  .get-document {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $dragon_green_1;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
    color: $white;
    height: 40px;
    margin: 0 auto;
    border-radius: 6px;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
    width: 233px;
    .original_price {
      text-decoration: line-through;
      padding: 0 2px;
    }
    &::before {
      position: absolute;
      z-index: auto;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      content: '';
      border-top: 12px solid $yellow;
      border-left: 12px solid $yellow;
      border-bottom: 12px solid transparent;
      border-right: 12px solid transparent;
      @include media_query(XL) {
        border-top: 15px solid $yellow;
        border-left: 15px solid $yellow;
        border-bottom: 15px solid transparent;
        border-right: 15px solid transparent;
      }
    }
    @include media_query(L) {
      height: 64px;
      width: 309px;
      font-size: 18px;
      line-height: 18px;
      margin-bottom: 0;
    }
    @include media_query(XL) {
      margin: 0;
    }

    .get-document-submit {
      background: $dragon_green_1;
      border: none;
      color: $white;
      font-weight: 600;

      @include media_query(L) {
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
      }
    }
  }
  .offer_tag_img {
    position: absolute;
    top: 4px;
    right: 13px;
    width: 44px;
    height: 41px;
    z-index: 1;
    @include media_query(L) {
      width: 50px;
      height: 50px;
      top: 10px;
      right: 19px;
    }
    @include media_query(XL) {
      top: -18px;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  .offer_tag_img_hi_mi {
    position: absolute;
    top: 4px;
    right: 155px;
    width: 44px;
    height: 41px;
    z-index: 1;
    @include media_query(L) {
      width: 50px;
      height: 50px;
      right: auto;
      left: 36px;
    }
    @include media_query(XL) {
      left: 60px;
      top: -18px;
      width: 44px;
      height: 41px;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  .offer_text {
    text-align: center;
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 12px;
    @include media_query(L) {
      font-size: 14px;
      // position: absolute;
      top: -30px;
      width: 100%;
    }
    @include media_query(XL) {
      position: absolute;
    }
  }
  .sample-document {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: $dragon_green_1;
    height: 40px;
    margin: auto;
    border-radius: 8px;
    border: solid 1px $dragon_green_1;
    margin-bottom: 25px;
    width: 233px;
    .share_icon {
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }
  }
  .doc_btn_container {
    // margin: 10px auto 0 auto;
    @include media_query(L) { 
      width: 309px;
      margin: 0;
    }
    @include media_query(XL) {
      margin: 0;
    }
    .offer_text {
      @include media_query(XL) {
        position: absolute;
        top: -30px;
        width: 100%;
        font-size: 18px;
      }
    }
    .offer_tag_img {
      @include media_query(XL) {
        width: 50px;
        height: 50px;
        top: -25px;
      }
    }
  }
  .include-text {
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    color: $blue;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    margin-top: 40px;
    @include media_query(L) {
      margin-top: 20px;
      padding: 0 20px;
      line-height: 20px;
    }
  }

  .share-house-container {
    margin-top: 20px;

    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
    height: 50px;
    background: $light_gray_2;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    position: relative;

    .share-image-wrapper {
      margin-left: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;

      //   .share-image {}
    }

    .share-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      color: $blue;
    }

    .forward-wrapper {
      margin-left: 10%;
      position: absolute;
      right: 20px;

      // .forward-image {}
    }
  }

  .save-more-wrapper {
    display: flex;
    margin: 20px;
    height: 90px;
    align-items: center;
    background: $blue;
    border-radius: 4px;
    @include media_query(L) {
      border-radius: 0px;
    }
    .image-container {
      .image-wrapper {
        width: 48px;
        height: 48px;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 10px;

        // .image {}
      }
    }

    .text {
      color: $white;
      font-size: 14px;
      font-weight: 500;
      padding-right: 10px;
    }

    .read-more {
      text-decoration: underline;
      color: $dragon_green;
    }
  }

  .info-container {
    display: flex;
    justify-content: center;
    padding: 0 20px;
    @include media_query(L) {
      flex-direction: column;
    }
    .info-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: $light_gray_2;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      padding: 11px 15px;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
      @include media_query(L) {
        margin-bottom: 20px;
        flex-direction: row;
        margin-right: 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .image-wrapper {
        height: 64px;
        width: 64px;
        @include media_query(L) {
          height: 40px;
          width: 40px;
        }
        .image {
          height: 100%;
          width: 100%;
        }
      }

      .text {
        font-size: 14px;
        line-height: 16px;
        font-weight: 600;
        color: $blue;
        text-align: center;
        //width: 50%;
      }
    }
  }
}
.social_icon_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-right: 20px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
  }
}