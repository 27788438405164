// Declaring Media Queries...

@mixin media_query($point) {
	@if $point==XXXL {
		@media only screen and (min-width: 1440px) {
			@content;
			/* Anything you write inside the curly braces of the @include will be generated in place of the @content directive in our mixin. */
		}
	}

	/* Large Devices, Wide Screens */
	@else if $point==XXL {
		@media only screen and (min-width: 1200px) {
			@content;
		}
	}

	/* Medium Devices, Desktops */
	@else if $point==XL {
		@media only screen and (min-width: 992px) {
			@content;
		}
	}

	/* Small Devices, Tablets */
	@else if $point==L {
		@media only screen and (min-width: 769px) {
			@content;
		}
	}

	/* Small Devices */
	@else if $point==M {
		@media only screen and (min-width: 575px) {
			@content;
		}
	}

	/* Extra Small Devices, Phones */
	@else if $point==S {
		@media only screen and (min-width: 480px) {
			@content;
		}
	}

	/* Custom, iPhone Retina */
	@else if $point==XS {
		@media only screen and (min-width: 320px) {
			@content;
		}
	}
}