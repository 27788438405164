

//new

.login-container-otp {
  padding: 22px 26px;

  @include media_query(L) {
    padding: 22px 85px;
  }

  .back-mobile {
    width: 16px;
    height: 8px;
    margin-bottom: 50px;
    font-weight: 600;
    font-size: large;
    cursor: pointer;

    @include media_query(L) {
      display: none;
    }
  }

  .logo-with-text {
    display: none;
    @include media_query(L) {
      display: block;
    }
  }

  .error-text2 {
    font-weight: 500;
    font-size: 14px;
    line-height: 133.4%;
    color: $error_orange;
    margin-bottom: 14px;
    position: absolute;
    top: 25px;

    @include media_query(L) {
      text-align: center;
      top: 30px;
    }
  }

  .login-text {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 133.4%;
    color: $dragon_green;
    margin-bottom: 25px;
  }

  .form-wrapper-input {
    width: fit-content;
    margin: auto;

    @include media_query(L) {
      width: fit-content;
      margin: auto;
    }

    .label-wrapper1 {
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      position: relative;

      .img-wrapper {
        height: 17px;
        width: 17px;
        margin-right: 5px;
        @include media_query(L) {
          width: 24px;
          height: 24px;
        }
        .img {
          height: 100%;
          width: 100%;
        }
      }

      .label-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 133.4%;
        color: $blue;

        @include media_query(L) {
          font-size: 16px;
        }
      }
    }

    .form-input {
      background: $light_cream_1;
      border: 1px solid $gray91;
      box-sizing: border-box;
      border-radius: 8px;
      height: 44px;
      width: 100%;
      margin-bottom: 21px;
      padding-left: 5px;
    }

    .form-input1 {
      background: $light_cream_1;
      border: 1px solid $gray91;
      box-sizing: border-box;
      border-radius: 8px;
      height: 44px;
      width: 80%;
      margin-bottom: 0px;
      padding-left: 5px;
    }

    .input-error-margin {
      margin-bottom: 7px;
    }

    .input-container1 {
      width: 24px !important;
      height: 30px;
      margin-right: 7px;
      margin-bottom: 0px;
      background: $light_cream_1;
      border: 1px solid $gray91;
      box-sizing: border-box;
      border-radius: 4px;

      @include media_query(L) {
        height: 40px;
        margin-right: 7px;
      }
    }
    .input-error {
      border: 1px solid $error_orange;
    }
  }

  .form-wrapper-button {
    .btn-container {
      height: 40px;
      margin-top: 50px;

      @include media_query(L) {
        width: fit-content;
        margin: auto;
        margin-top: 50px;
      }

      .next-btn-container {
        background: $dragon_green_1;
        // box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
        border-radius: 6px;
        width: 80%;
        margin: auto;
        height: 100%;
        color: $white;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        border-color: $dragon_green;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;

        @include media_query(L) {
          font-weight: 600;
          font-size: 20px;
          line-height: 133.4%;
          height: 56px;
          width: 272px;
        }
      }
    }
  }
}

//new email

.login-container-email {
  padding: 22px 26px;

  @include media_query(L) {
    padding: 22px 85px;
  }

  .back-mobile {
    width: 16px;
    height: 8px;
    margin-bottom: 50px;
    font-weight: 600;
    font-size: large;
    cursor: pointer;

    @include media_query(L) {
      display: none;
    }
  }

  .logo-with-text {
    display: none;
    @include media_query(L) {
      display: block;
    }
  }

  .error-text2 {
    font-weight: 500;
    font-size: 14px;
    line-height: 133.4%;
    color: $error_orange;
    margin-bottom: 14px;

    @include media_query(L) {
      margin-bottom: 20px;
      text-align: center;
    }
  }

  .login-text {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 133.4%;
    color: $dragon_green;
    margin-bottom: 25px;
  }

  .form-wrapper-input {
    width: 100%;
    margin: auto;

    @include media_query(L) {
      width: fit-content;
      margin: auto;
    }

    .label-wrapper1 {
      display: flex;
      margin-bottom: 25px;

      .img-wrapper {
        height: 17px;
        width: 17px;
        margin-right: 5px;

        .img {
          height: 100%;
          width: 100%;
        }
      }

      .label-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 133.4%;
        color: $blue;

        @include media_query(L) {
          font-size: 16px;
        }
      }
    }

    .form-input {
      background: $light_cream_1;
      border: 1px solid $gray91;
      box-sizing: border-box;
      border-radius: 8px;
      height: 44px;
      width: 100%;
      margin-bottom: 21px;
      padding-left: 5px;
    }

    .form-input1 {
      background: $light_cream_1;
      border: 1px solid $gray91;
      box-sizing: border-box;
      border-radius: 8px;
      height: 44px;
      width: 100%;
      margin-bottom: 0px;
      padding-left: 5px;
    }

    .input-error-margin {
      margin-bottom: 7px;
    }

    .input-container1 {
      width: 24px !important;
      height: 30px;
      margin-right: 7px;
      margin-bottom: 0px;
      background: $light_cream_1;
      border: 1px solid $gray91;
      box-sizing: border-box;
      border-radius: 4px;

      @include media_query(L) {
        height: 40px;
        margin-right: 7px;
      }
    }
    .input-error {
      border: 1px solid $error_orange;
    }
  }

  .form-wrapper-button {
    .btn-container {
      height: 40px;
      width: 80%;
      margin: auto;
      margin-top: 40px;

      @include media_query(L) {
        width: fit-content;
        margin: auto;
        margin-top: 50px;
      }

      .next-btn-container {
        background: $dragon_green_1;
        // box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
        border-radius: 6px;
        width: 80%;
        margin: auto;
        height: 100%;
        color: $white;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        border-color: $dragon_green;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;

        @include media_query(L) {
          font-weight: 600;
          font-size: 20px;
          line-height: 133.4%;
          height: 56px;
          width: 272px;
        }
      }
    }
  }
}
