.show-full-screen {
  height: 100vh;
  width: 100%;
}
.back_btn_wrapper {
  position: absolute;
  top: 25px;
  left: 15px;
  z-index: 3;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  .back_icon {
    margin-right: 12px;
  }
  .lbl {
    display: none;
    @include media_query(L) {
      display: inline-block;
      color: $white;
      font-weight: 500;
      font-size: 21px;
      line-height: 133.4%;
    }
  }
}
.share_btn_wrapper {
  position: absolute;
  top: 80px;
  right: 20px;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  align-items: center;
  justify-content: center;
  z-index: 3;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: $dragon_green;
  @include media_query(L) {
    bottom: 70px;
    left: 310px;
    top: auto;
    right: auto;
    width: 232px;
    height: 50px;
    border-radius: 8px;
    padding: 13px 16px;
  }
  .share_icon {
    width: 24px;
    height: 24px;
    margin-right: 3px;
    @include media_query(L) {
      margin-right: 12px;
    }
  }
  .lbl {
    display: none;
    @include media_query(L) {
      display: inline-block;
      color: $white;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
    }
  }
}