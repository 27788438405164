.congratulations-container {
  overflow-y: auto;
  height: 100vh;
  .banner-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: linear-gradient(0.04deg, #00b3a9 -24.39%, #00c6bb 100.13%);
    padding: 30px 30px 30px 30px;
    position: relative;
    height: 50%;
    @include media_query(L) {
      height: 60%;
    }
    @include media_query(XL) {
      padding: 50px; 
      height: auto;
    }
    .info_container {
      max-width: 460px;
      position: relative;
      @include media_query(XL) {
        max-width: 600px;
      }
      .img_area {
        display: flex;
        align-items: center;
        flex-direction: column;
        .congrats_img {
          margin-bottom: 20px;
        }
      }
    }
    .money_img {
      position: absolute;
      bottom: -15px;
      width: 55px;
      height: 30px;
      left: 0;
      right: 0;
      margin: auto;
      @include media_query(XL) {
        bottom: -32px;
        width: 114px;
        height: 64px;
      }
      img {
        height: 100%;
        width: 100%;
      }
    }
    .congratulations_text_area {
      color: $white;
      text-align: center;
      text-transform: uppercase;
      .title {
        font-size: 18px;
        margin-bottom: 10px;
        font-weight: 700;
        @include media_query(L) {
          font-size: 28px;
        }
        @include media_query(XL) {
          font-size: 38px;
        }
        @include media_query(XXL) {
          font-size: 54px;
          line-height: 66px;
          margin-bottom: 20px;
        }
      }
      .sub_title {
        font-size: 14px;
        font-weight: 500;
        @include media_query(L) {
          font-size: 20px;
        }
        @include media_query(XL) {
          font-size: 28px;
        }
      }
    }
  }
  .savings_info_container {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50%;
    @include media_query(L) {
      height: 40%;
    }
    @include media_query(XL) {
      padding: 70px; 
      height: auto;
    }
    .savings,.inr {
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      margin-bottom: 10px;
      @include media_query(XL) {
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
      }
    }
    .rupee-wrapper {
      margin-right: 5px;
      @include media_query(XL) {
        margin-right: 10px;
      }
    }
    .amount-wrapper {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 40px;
      line-height: normal;
      color: $dragon_green;
      margin-bottom: 5px;
      @include media_query(XL) {
        font-size: 54px;
        margin-bottom: 10px;
      }
    }
    .inr {
      color: $light_gray_1;
      font-weight: 400;
      margin-bottom: 15px;
      @include media_query(XL) {
        margin-bottom: 20px;
      }
    }
    .description {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: $blue;
      max-width: 958px;
      text-align: center;
      margin-bottom: 30px;
      @include media_query(L) {
        font-weight: 400;
        font-size: 24px;
      line-height: 32px;
      }
    }
    .continue {
      font-weight: 600;
      @include media_query(L) {
        width: 346px;
      }
    }
  }
}
