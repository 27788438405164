@import './scss/common/all.scss';
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "react-widgets/styles.css";
// @import './components/dashboard.scss';
// @import './components/details.scss';
// @import './components/header.scss';
// @import './components/logo-with-text.scss';
// @import './components//sign-up.scss';
// @import './components/login.scss';
// @import './components/otp.scss';
// @import './components/terms-and-conditions.scss';
// @import './components/welcome.scss';
// @import './components/confirmation.scss';
@import "react-multi-carousel/lib/styles.css";
// @import './components/design-edit-my-house.scss';
// @import './components/houses.scss';
// @import './components/sample-document.scss';
// @import './components/our-story.scss';
// @import './components/my-profile.scss';
// @import './components/faq.scss';
// @import './components/full-screen.scss';
// @import './components/share-pop-up.scss';
// @import './components/visava-vendor.scss';
// @import './components/you-tube-modal.scss';

@import '../../client/components/confirmation/confirmation.scss';
@import '../../client/components/dashboard/dashboard.scss';
@import '../../client/components/designMyHouse/design-edit-my-house.scss';
@import '../../client/components/details/details.scss';
@import '../../client/components/faq/faq.scss';
@import '../../client/components/homes/houses.scss';
@import '../../client/components/login/login.scss';
@import '../../client/components/logoWithText/logo-with-text.scss';
@import '../../client/components/myProfile/my-profile.scss';
@import '../../client/components/ourStory/our-story.scss';
@import '../../client/components/sampleDocument/sample-document.scss';
@import '../../client/components/sharePopup/share-pop-up.scss';
@import '../../client/components/signup/sign-up.scss';
@import '../../client/components/viewFullScreen/full-screen.scss';
@import '../../client/components/visavaVendor/visava-vendor.scss';
@import '../../client/components/welcome/welcome.scss';
@import '../../client/components/youTubeModal/you-tube-modal.scss';

@import '../../client/shared-ui/header/header.scss';
@import '../../client/shared-ui/otp/otp.scss';
@import '../../client/shared-ui/t&c/terms-and-conditions.scss';
@import '../../client/components/editHouseName/edit-house-name.scss';
@import '../../client/components/errorPopup/error-popup.scss';
@import '../../client/components/googleMaps/google-maps.scss';
@import '../../client/components/verifyEmailPhone/verify-email-phone.scss';
// @import '../../client/components/bomDocument/bomDocument.scss';
@import '../../client/components/directBomButtons/directBomButtons.scss';

//button pointer

.button-pointer{
  cursor: pointer;
}

// Spinner Starts

.spinner-container {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  // pointer-events: none;
  &.sm {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    pointer-events: none;
    border-radius: 30px;
    cursor: default;

    .spinner {
      width: 30px;
      height: 30px;
    }
  }

  .spinner {
    background-image: url(../images/Spinner.svg);
    width: 100px;
    height: 100px;
    background-size: contain;
    background-repeat: no-repeat;

    &.sm {
      width: 20px;
      height: 20px;
    }
  }
}
.main-wrapper {
  &.hi {
    font-family: 'Martel' !important;
    .bold {
      font-family: 'Martel-bold' !important;
    }
  }
  &.mr {
    font-family: 'Martel' !important;
    .bold {
      font-family: 'Martel-bold' !important;
    }
  }
  &.en {
    font-family: 'Metropolis' !important;
    .bold {
      font-family: 'Metropolis' !important;
    }
  }
}
.english_font {
  font-family: 'Metropolis' !important;
  .bold {
    font-family: 'Metropolis' !important;
  }
}