::placeholder { 
  color: $placeholder;
  opacity: 1; 
}
.tablet-view {
  display: none;
  @include media_query(L) {
    display: block;
    padding: 120px 0;
    .design-my-house-contaienr-L {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .design-my-house-container {
      width: 100%;
    }
    .lower-area {
      display: flex;
      padding: 0 70px;
      .section-1-container {
        width: 50%;
        max-width: 50%;
        max-height: 100%;
      }
    }
  }
  @include media_query(XL) {
    display: none;
  }
}
.design-my-house-contaienr-L {
  display: none;
  @include media_query(XL) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding: 150px 30px 0 30px;
    margin-top: 0;
    height: auto;
    max-width: 1440px;
    margin: 0 auto 0 auto;
    box-sizing: border-box;
    padding: 210px 30px 60px 30px;
  }
  @include media_query(XXL) {
    padding: 210px 30px 60px 30px;
  }
  .section-1-container {
    width: 342px;
    max-width: 30%;
    border: 1px solid #C4C4C4;
    border-radius: 14px;
    margin-right: 30px;
    //height: 505px;
    max-height: 500px;
    overflow-y: auto;
    box-sizing: border-box;
    @include media_query(XXL) {
      max-width: 342px;
      max-height: 510px;
      margin-right: 60px;
    }

    .section-1-wrapper { 

      .section-1-inner-wrapper {
        padding: 20px;
        font-size: 14px; 
        position: relative;
        .text {
          font-weight: 600;
          font-size: 18px;
          line-height: 133.4%;
          color: $light_gray;
          margin-bottom: 20px;
          @include media_query(L) {
            font-weight: 500;
          }
        }
        .btn_area {
          padding: 0;
          height: 64px;
          width: 100%;
          padding: 0 6px;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          z-index: 20;
          box-sizing: border-box;
          cursor: pointer;
          .txt {
            background-color: $white;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-weight: 600;
            color: $dragon_green;
            padding: 10px;
            text-decoration: underline;
            box-sizing: border-box;
            @include media_query(L) {
              font-size: 15px;
            }
            @include media_query(XXL) {
              font-size: 18px;
            }
          }
        }
        .image-wrapper {
          img {
            width: 100%;
            height: 100%;
          }
        }
        .overlay {
          position: absolute;
          height: 100%;
          width: 100%;
          background-color: $black;
          opacity: 0.3;
        }

        .iframe-class{
          width: 98%;
          height: auto;
        }
        
      }

      .divider{
        height: 0.7px;
        width: 100%;
        background-color: #C4C4C4;
      }

      .section-1-inner-wrapper-2{
        padding: 25px;
        font-size: 14px; 

        .save-more {
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
          margin-top: 10px;
          color: $light_gray;
          margin-bottom: 20px;
        }
        .read_more {
          color: $dragon_green;
          text-decoration: underline;
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
          
        }
        .details {
          font-weight: 500;
          font-size: 1rem;
          color: $light_gray;
        }
        
      }
    }
  }
  .right_section {
    margin-right: 0;
    @include media_query(XXL) {
      width: 342px;
      overflow: hidden;
    }
  }
}

.design-my-house-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  //height: calc(100vh - 65px);
  overflow-y: auto;
  @include media_query(L) {
    overflow-y: unset;
  }
  @include media_query(XL) {
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);
    width: 40%;
    margin-right: 30px;
    max-width: 500px;
    height: 555px;
    overflow-y: unset;
  }
  @include media_query(XXL) {
    margin-right: 60px;
    height: 700px;
  }
  .button-wrapper {
    background-color: $white;
    position: fixed;
    z-index: 2;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 40px);
    height: 60px;
    @include media_query(L) {
      position: static;
      width: 100%;
    }
    @include media_query(XL) {
      height: 94px;
      position: absolute;
      bottom: 0;
      width: calc(100% - 40px);
    }
    @include media_query(XXL) {
      width: calc(100% - 100px);
    }
    .location-next-btn {
      margin: 0 auto;
    }
    .back-button {
      color: $dragon_green;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      width: 40px;
      font-size: 12px;
      font-weight: 600;
      cursor: pointer;
      @include media_query(L) {
        font-size: 16px;
        width: 56px;
        height: 64px;
      }
      .left_arrow {
        width: 8px;
        height: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
        }
        @include media_query(XL) {
          height: 12px;
        }
      }
    }
    .visava-btn {
      @include media_query(XL) {
        width: 170px;
        height: 54px;
        font-size: 18px;
      }
      @include media_query(XXL) {
        width: 272px;
      }
    }
  }
  .confirm-location-container {
    margin-top: 12px;
    margin-left: 40px;
    margin-right: 8px;

    .location-name-container {
      display: flex;
      align-items: center;
      margin-bottom: 3px;
      margin-left: 60px;

      .image-wrapper {
        height: 17px;
        width: 16px;
        margin-right: 6px;

        .image {
          height: 100%;
          width: 100%;
          // object-fit: contain;
        }
      }

      .location-name {
        font-size: 18px;
        font-weight: 600;
        color: $blue;
        
      }
    }

    .location-detail {
      font-size: 12px;
      font-weight: 400;
      color: $blue;
      margin-right: 10px;
      margin-bottom: 10px;
      margin-left: 60px;
    }

    .confirm-location-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      background-color: $dragon_green;
      margin-left: 46px;
      margin-right: 54px;
      height: 40px;
      font-size: 14px;
      font-weight: 600;
      border-radius: 8px;
      margin-bottom: 30px;
    }

  }

  .google-map {
    margin-left: 20px;
    margin-right: 10px;

    .google-map-container {
      display: flex;
      margin-bottom: 10px;

      .back-btn-container {
        height: 38px;
        width: 16px;
        display: flex;
        align-items: center;
        margin-right: 16px;

        .back-btn {
          height: 16px;
          width: 100%;
        }
      }

      .search-location {
        height: 38px;
        background: $white;
        border: 1px solid $blue;
        box-sizing: border-box;
        width: 100%;
        border-radius: 8px;
        margin-bottom: 16px;
      }
    }

    .current-location-container {
      display: flex;

      .cur-loc-img-container {
        height: 35px;
        width: 35px;
        margin-right: 15px;
        display: flex;
        align-items: center;

        .img-wrapper {
          width: 100%;
          height: 100%;
        }
      }

      .current-location-wrapper {
        display: flex;
        flex-direction: column;

        .current-location {
          font-size: 14px;
          font-weight: 600;
          color: $blue;
          margin-bottom: 3px;
        }

        .using-gps {
          font-size: 14px;
          font-weight: 400;
          color: $light_gray;
        }
      }
    }
  }

  .image-container {
    position: relative;
    border-bottom: 1px solid $blue;

    .image-wrapper { 
      height: 100%;
      width: 100%;
      display: block;
    }
  }

  .dmh-second-container {
    padding: 15px 20px;
    overflow-y: auto;
    margin-bottom: 36px;
    @include media_query(L) {
      padding: 40px 70px;
      margin-bottom: 0;
    }
    @include media_query(XL) {
      margin-bottom: 0;
      padding: 20px;
    }
    @include media_query(XXL) {
      padding: 25px 50px 100px;
    }
    .container-1 {
      .location {
        font-weight: 600;
        font-size: 14px;
        line-height: 133.4%;
        color: $blue;
        margin-bottom: 10px;
        @include media_query(L) {
          font-size: 18px;
          line-height: 24px;
        }
        @include media_query(XXL) {
          font-size: 24px;
          line-height: 32px;
        }
      }

      .location-container {
        position: relative;
        width: 100%;
        margin-bottom: 16px;

        .search-location {
          background: $white;
          border: 1px solid $blue;
          box-sizing: border-box;
          width: 100%;
          height: 36px;
          border-radius: 8px;
          margin-bottom: 0px;
          padding: 9px 9px 9px 25px;
          font-size: 14px;
          font-weight: 500;
          line-height: 19px;
          color: $black;
          @include media_query(L) {
            font-size: 16px;
            line-height: 24px;
          }
          @include media_query(XXL) {
            height: 44px;
            padding: 10px 10px 10px 40px;
            font-size: 18px;
            line-height: 24px;
          }
          &.place_holder {
            color: $placeholder;
          }
          &::placeholder {
            color: $placeholder;
          }
        }

        .location-class {
          position: absolute;
          left: 10px;
          top: 0;
          bottom: 0;
          margin: auto;
          @include media_query(XXL) {
            width: 17px;
            height: 22px;
            left: 13px;
          }
        }
      }

      .search-location {
        background: $white;
        border: 1px solid $blue;
        box-sizing: border-box;
        width: 100%;
        height: 36px;
        border-radius: 8px;
        margin-bottom: 16px;
      }

      .description {
        font-size: 14px;
        line-height: 17px;
        color: $blue;
        font-weight: 400;
        @include media_query(XXL) {
          font-size: 16px;
          line-height: 24px;
        }
        margin-bottom: 15px;
      }

      .button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        background: $dragon_green;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
        border-radius: 6px;
        margin: auto;
        width: 233px;
        color: $white;
        margin-top: 33px;
        font-size: 14px;
        line-height: 14px;
        font-weight: 600;
        @include media_query(L) {
          box-shadow: none;
          width: 200px;
        }
        @include media_query(L) {
          font-size: 18px;
          line-height: 28px;
          border-radius: 4px;
        }
        @include media_query(XXL) {
          font-size: 24px;
          line-height: 32px;
          width: 272px;
          height: 64px;
          padding: 0px 0px;
        }
      }
    }

    .container-2 {
      .const-area {
        font-weight: 600;
        font-size: 14px;
        line-height: 133.4%;
        color: $blue;
        margin-bottom: 10px;
        @include media_query(L) {
          font-size: 18px;
          line-height: 24px;
        }
        @include media_query(XXL) {
          font-size: 24px;
          line-height: 32px;
        }
      }

      .length {
        display: flex;
        width: 40%;
        height: 28px;
        color: #B8B8B8;
        box-sizing: border-box;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 10px;
        overflow: hidden;
        @include media_query(L) {
          height: 40px;
          font-size: 14px;
          line-height: 20px;
        }
        @include media_query(XXL) {
          font-size: 18px;
          line-height: 24px;
        }
        .feet {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-top: 1px solid $border_color;
          border-left: 1px solid $border_color;
          border-bottom: 1px solid $border_color;
          border-radius: 8px 0 0 8px;
        }

        .meter {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-top: 1px solid $border_color;
          border-right: 1px solid $border_color;
          border-bottom: 1px solid $border_color;
          border-radius: 0 8px 8px 0;
        }

        .selected {
          background-color: $dragon_green;
          //border-radius: 8px;
          color: $white;
          border: 1px solid transparent;
        }

        .border-meter {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
        }

        .border-feet {
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
        }
      }

      .length-A-container {
        display: flex;
        flex-direction: column;

        .length-input-container {
          border-radius: 8px;
          height: 36px;
          margin-bottom: 15px;
          padding: 0 9px 0 59px;
          border: 1px solid $blue;
          box-sizing: border-box;
          font-size: 14px;
          width: 100%;
          @include media_query(XXL) {
            height: 44px;
            padding: 10px 16px 10px 76px;
            font-size: 18px;
            line-height: 24px;
          }
          &.error {
            border-color: $error_orange;
          }
        }
        .length_input_wrapper {
          position: relative;
          .length_unit_lbl {
            position: absolute;
            top: 9px;
            left: 9px;
            z-index: 1;
            color: #B3B8C5;
            font-size: 14px;
            line-height: 16px;
            width: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            @include media_query(XXL) {
              width: 65px;
              font-size: 17px;
              line-height: 19px;
              left: 10px;
              top: 10px;
            }
          }
        }
        .length-text {
          color: $blue;
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 6px;
          @include media_query(XXL) {
            font-size: 18px;
            line-height: 24px;
          }
        }

        .dropdown {
          border-radius: 8px;
          height: 40px;
        }

        .container {
          position: relative;
          display: inline-block;
        }

        .button {
          padding: 0;
          width: 50px;
          border: 0;
          background-color: #fff;
          color: #333;
          cursor: pointer;
          outline: 0;
          font-size: 40px;
        }

        .dropdown {
          position: absolute;
          top: 100%;
          left: 0;
          width: 300px;
          z-index: 2;
          border: 1px solid rgba(0, 0, 0, 0.04);
        }

        ul {
          list-style: none;
          padding: 0;
          margin: 0;
        }

        li {
          padding: 8px 12px;
        }

        li:hover {
          background-color: rgba(0, 0, 0, 0.14);
          cursor: pointer;
        }
      }

      .description {
        font-size: 13px;
        line-height: 17px;
        color: $blue;
        font-weight: 400;
        @include media_query(L) {
          font-size: 16px;
          line-height: 24px;
        }
      }

      .button-container {

        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        @include media_query(L) {
          height: 64px;
          margin-top: 24px;
        }
        .back-button {
          color: $dragon_green;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 100%;
          width: 20%;
          font-size: 12px;
          font-weight: 600;
          @include media_query(L) {
            font-size: 16px;
            width: 60px;
          }
        }

        .next-button {
          display: flex;
          justify-content: center;
          align-items: center;
          background: $dragon_green;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
          border-radius: 6px;
          color: $white;
          height: 100%;
          width: 233px;
          font-size: 14px;
          line-height: 14px;
          font-weight: 600;
          @include media_query(L) {
            box-shadow: none;
            font-size: 18px;
            line-height: 28px;
            border-radius: 4px;
            width: 200px;
          }
          @include media_query(XXL) {
            font-size: 24px;
            line-height: 32px;
            width: 272px;
          }
        }
      }

    }

    .container-3 {
      .main-door {
        font-weight: 600;
        font-size: 14px;
        line-height: 133.4%;
        color: $blue;
        margin-bottom: 10px;
        @include media_query(L) {
          font-size: 18px;
          line-height: 24px;
        }
        @include media_query(XXL) {
          font-size: 24px;
          line-height: 32px;
        }
      }

      .dropdown-container {
        margin-bottom: 20px;
        .rw-widget-picker {
          min-height: 36px;
        }
        .rw-widget-input {
          border-radius: 8px;
          height: 36px;
          border-color: $blue;
          @include media_query(XXL) {
            height: 44px;
            font-size: 18px;
            line-height: 24px;
          }
        }
        .rw-dropdown-list-input {
          @include media_query(XXL) {
            padding: 10px 20px;
          }
        }
        .rw-dropdown-list-input > * {
          color: $blue;
          line-height: 26px;
        }
      }

      .dropdown-error {
        margin-bottom: 20px;

        .rw-widget-input {
          border-radius: 8px;
          border-color: $error_orange;
        }
      }

      .description {
        font-size: 13px;
        line-height: 17px;
        color: $blue;
        font-weight: 400;
        @include media_query(L) {
          font-size: 16px;
          line-height: 24px;
        }
        @include media_query(XXL) {
          font-size: 18px;
          line-height: 24px;
        }
        margin-bottom: 95px;
      }

      .button-container {

        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        @include media_query(L) {
          height: 64px;
          margin-top: 24px;
        }
        .back-button {
          color: $dragon_green;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 100%;
          width: 20%;
          font-size: 12px;
          font-weight: 600;
          @include media_query(L) {
            font-size: 16px;
            width: 60px;
          }
        }

        .next-button {
          display: flex;
          justify-content: center;
          align-items: center;
          background: $dragon_green;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
          border-radius: 6px;
          color: $white;
          height: 100%;
          width: 80%;
          font-size: 14px;
          font-weight: 600;
          @include media_query(L) {
            box-shadow: none;
            font-size: 18px;
            line-height: 28px;
            border-radius: 4px;
            width: 200px;
          }
          @include media_query(XXL) {
            font-size: 24px;
            line-height: 32px;
            width: 272px;
          }
        }
      }
    }

    .container-4 {
      .preference {
        font-weight: 600;
        font-size: 14px;
        line-height: 133.4%;
        color: $blue;
        margin-bottom: 10px;
        @include media_query(L) {
          font-size: 18px;
          line-height: 24px;
        }
        @include media_query(XXL) {
          font-size: 24px;
          line-height: 32px;
        }
      }

      .dropdown-container {
        margin-bottom: 14px;
        .rw-widget-picker {
          min-height: 36px;
        }
        .rw-widget-input {
          border-radius: 8px;
          height: 36px;
          line-height: 24px;
          border-color: $blue;
          @include media_query(XXL) {
            height: 44px;
            font-size: 18px;
            line-height: 28px;
          }
        }
        .rw-dropdown-list-input {
          @include media_query(XXL) {
            padding: 10px 20px;
          }
        }
        .rw-dropdown-list-input > * {
          color: $blue;
          line-height: 26px;
        }
      }

      .dropdown-error {
        margin-bottom: 20px;

        .rw-widget-input {
          border-radius: 8px;
          border-color: $error_orange;
        }
      }

      .preference-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;

        .preference-wrapper {
          width: 45%;

          .text {
            color: $blue;
            font-size: 12px;
            font-weight: 700;
            margin-bottom: 8px;
            @include media_query(L) {
              font-size: 14px;
              line-height: 20px;
            }
            @include media_query(XXL) {
              font-size: 18px;
              line-height: 24px;
            }
          }
        }

        .description {
          font-size: 13px;
          line-height: 17px;
          color: $blue;
          font-weight: 400;
          @include media_query(L) {
            font-size: 16px;
            line-height: 24px;
          }
          @include media_query(XXL) {
            font-size: 18px;
            line-height: 24px;
          }
          margin-bottom: 15px;
        }
      }

      .preference-wrapper-1 {
        width: 45%;
        margin-bottom: 50px;

        .text {
          color: $blue;
          font-size: 12px;
          font-weight: 700;
          margin-bottom: 8px;
        }
      }

      .button-container {

        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        @include media_query(L) {
          height: 64px;
          margin-top: 24px;
        }

        .back-button {
          color: $dragon_green;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 100%;
          width: 20%;
          font-size: 12px;
          font-weight: 600;
          @include media_query(L) {
            font-size: 16px;
            width: 60px;
          }
        }

        .next-button {
          display: flex;
          justify-content: center;
          align-items: center;
          background: $dragon_green;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
          border-radius: 6px;
          color: $white;
          height: 100%;
          width: 80%;
          font-size: 14px;
          font-weight: 600;
          @include media_query(L) {
            box-shadow: none;
            font-size: 18px;
            line-height: 28px;
            border-radius: 4px;
            width: 200px;
          }
          @include media_query(XXL) {
            font-size: 24px;
            line-height: 32px;
            width: 272px;
          }
        }
      }

    }

    .container-5 {
      .house-budget {
        font-weight: 600;
        font-size: 14px;
        line-height: 133.4%;
        color: $blue;
        margin-bottom: 10px;
        @include media_query(L) {
          font-size: 18px;
          line-height: 24px;
        }
        @include media_query(XXL) {
          font-size: 24px;
          line-height: 32px;
        }
      }

      .dmh-budget-container {
        margin-bottom: 21px;
        position: relative;
        width: 100%;

        .search-location {
          background: $white;
          border: 1px solid $blue;
          box-sizing: border-box;
          width: 100%;
          height: 36px;
          border-radius: 8px;
          padding: 9px 9px 9px 30px;
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
          color: $black;
          @include media_query(L) {
            font-size: 16px;
            line-height: 24px;
          }
          @include media_query(XXL) {
            height: 44px;
            font-size: 18px;
            line-height: 24px;
            padding: 10px 10px 10px 34px;
          }
          &.error {
            border-color: $error_orange;
          }
          &::placeholder {
            color: $placeholder;
          }
        }

        .image {
          position: absolute;
          left: 15px;
          top: 0;
          bottom: 0;
          margin: auto;
          @include media_query(L) {
            width: 11px;
            height: 17px;
          }
        }
      }

      .description {
        font-size: 13px;
        line-height: 17px;
        color: $blue;
        font-weight: 400;
        @include media_query(L) {
          font-size: 16px;
          line-height: 24px;
        }
        @include media_query(XXL) {
          font-size: 18px;
          line-height: 24px;
        }
        margin-bottom: 15px;
      }

      .button-container {

        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        margin-top: 50;
        @include media_query(L) {
          height: 64px;
        }
        .back-button {
          color: $dragon_green;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 100%;
          width: 20%;
          font-size: 12px;
          font-weight: 600;
          @include media_query(L) {
            font-size: 16px;
            width: 60px;
          }
        }

        .next-button {
          display: flex;
          justify-content: center;
          align-items: center;
          background: $dragon_green;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
          border-radius: 6px;
          color: $white;
          height: 100%;
          width: 80%;
          font-size: 14px;
          font-weight: 600;
          @include media_query(L) {
            box-shadow: none;
            font-size: 18px;
            line-height: 28px;
            border-radius: 4px;
            width: 200px;
          }
          @include media_query(XXL) {
            font-size: 24px;
            line-height: 32px;
            width: 272px;
          }
        }
      }
    }
    .button-container {
      .left_arrow {
        height: 4px;
        width: 8px;
        margin-right: 8px;
        @include media_query(L) {
          margin-right: 12px;
        }
      }
    }

    .error-text {
      color: $error_orange;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 5px;
    }
  }
  .home_navigation_img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75px;
    height: 30px;
    border-top: 1px solid $blue;
    border-right: 1px solid $blue;
    border-left: 1px solid $blue;
    border-radius: 8px 8px 0 0;
    background-color: $white;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    padding: 5px;
    margin: 0 auto;
    @include media_query(L) {
      height: 34px;
      width: 95px;
    }
    img {
      height: 22px;
      width: 22px;
      margin-right: 5px;
      @include media_query(L) {
        height: 24px;
        width: 24px;
        margin-right: 10px;
      }
    }
    .nav_text {
      font-size: 14px;
      font-weight: 400;
      @include media_query(L) {
        font-size: 16px;
      }
      @include media_query(XL) {
        font-size: 18px;
      }
    }
  }
}
// steppers code
.design-my-house-container, .design-my-house-contaienr-L {
  .steppers_area {
    @include media_query(L) {
      top: -100px;
      position: absolute;
      width: 100%;
    }
    @include media_query(XXL) {
      top: -104px;
      position: absolute;
      width: 100%;
    }
    #RFS-StepButton {
      border-radius: 50%;
      width: 26px;
      height: 26px;
      border: 1px solid #ABABAB;
      background-color: #E3E3E3;
      @include media_query(XL) {
        width: 40px;
        height: 40px;
      }
      @include media_query(XXL) {
        width: 50px;
        height: 50px;
      }
      .StepButtonContent-0-2-5 {
        color: #ABABAB;
        font-size: 12px;
        line-height: 16px;
        font-weight: 700;
        @include media_query(L) {
          font-size: 16px;
          line-height: 24px;
        }
        @include media_query(XXL) {
          font-size: 24px;
          line-height: 32px;
          font-weight: 600;
        }
      }
      &.active {
        background-color: $dragon_green;
        border-color: #018289;
        .StepButtonContent-0-2-5 {
          color: $white;
          font-size: 12px;
          line-height: 16px;
          font-weight: 700;
          @include media_query(L) {
            font-size: 16px;
            line-height: 24px;
          }
          @include media_query(XXL) {
            font-size: 24px;
            line-height: 32px;
            font-weight: 600;
          }
        }
      }
      &.completed {
        font-size: 0;
        background-color: $dragon_green;
        border-color: #018289;
        background-image: url('../../../asset/images/check_white_icon.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 50%;
        .StepButtonContent-0-2-5 {
          display: none;
        }
      }
    }
    #RFS-LabelContainer {
      display: none;
    }
    .StepperContainer-0-2-1 {
      padding: 14px 0;
      @include media_query(L) {
        padding: 24px 0;
      }
    }

    .Label-0-2-41 {
      margin: 0;
    }
    #RFS-ConnectorContainer{
      left: calc((-50% + 50px) - 45px);
      right: calc((50% + 50px) - 45px);
      top: calc((2em - 7px) / 2);
      position: absolute;
      z-index: 0;
      @include media_query(XL) {
        top: calc(18px);
      }
      @include media_query(XXL) {
        left: calc((-50% + 50px) - 35px);
        right: calc((50% + 50px) - 35px);
        top: calc(23px);
      }
    }
    #RFS-Connector {
      display: block;
      border-color: rgb(227, 227, 227);
      border-top-style: solid;
      border-top-width: 3px;
      &.active {
        border-color: $dragon_green;
      } 
      &.completed {
        border-color: $dragon_green;
      }  
    }
    .StepButton-0-2-4 {
      z-index: 1;
      position: relative;
    }
  }
}
// end
// design my house
  .design_my_house_contaienr {
    padding: 122px 0 0 0;
    @include media_query(L) {
      padding: 210px 30px 30px 30px;
    }
    @include media_query(XL) {
      top: -105px;
    }
    .bootstrap-wrapper {
    .row {
      margin: 0;
      @include media_query(L) {
        margin-right: -15px;
        margin-left: -15px;
      }
      .col-md-4 {
        padding: 0;
        @include media_query(L) {
          padding-right: 15px;
          padding-left: 15px;
        }
      }
    }
  }
    .sample_document, .youtube_help_video {
      display: none;
      @include media_query(L) {
        display: block;
      }
    }
    .youtube_help_video {
      .sections_wrapper {
        border: 1px solid $border_color;
        border-radius: 14px;
        .help_inner_wrapper {
          padding: 25px 30px;
          border: none;
          border-bottom: 1px solid $border_color;
          border-radius: 0;
        }
        .description {
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          padding: 20px 25px;
        }
      }
    }
    .help_inner_wrapper, .sample_document_wrapper {
      border: 1px solid $border_color;
      border-radius: 14px;
      padding: 20px 25px;
      @include media_query(XL) {
        padding: 25px 30px;
      }
      .note {
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 15px;
        line-height: 24px;
      }
      .visava-video-container {
        height: 170px;
        margin-bottom: 10px;
      }
    }
    .sample_document_wrapper {
      .blur_image {
        position: relative;
        .btn_area {
          padding: 0;
          height: 64px;
          width: 100%;
          padding: 0 6px;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          z-index: 20;
          cursor: pointer;
          .txt {
            background-color: $white;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-weight: 600;
            color: $dragon_green;
            padding: 10px;
            text-decoration: underline;
            @include media_query(L) {
              font-size: 15px;
            }
            @include media_query(XXL) {
              font-size: 18px;
            }
          }
        }
        .overlay {
          position: absolute;
          height: 100%;
          width: 100%;
          opacity: 0.2;
        }
      }
    }
    .steps_wrapper {
      position: relative;
      .steppers_area {
        position: absolute;
        left: 0;
        top: -65px;
        width: 100%;
        @include media_query(L) {
          top: -90px;
        }
        @include media_query(XL) {
          top: -90px;
        }
      }
      .five_steps {
        padding-bottom: 25px;
        @include media_query(L) {
          box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);
          border-radius: 2px;
        }
        .step_two, .step_three, .step_four {
          .home_config_area {
            .form-input {
              padding-left: 15px;
            }
            .rw-widget-input {
              padding-left: 15px;
            }
          }
        }
      }
    }
    .home_img_area {
      border-bottom: 1px solid $blue;
      position: relative;
      img {
        height: 100%;
        width: 100%;
        display: block;
      }
      .home_navigation_img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 75px;
        height: 30px;
        border-top: 1px solid $blue;
        border-right: 1px solid $blue;
        border-left: 1px solid $blue;
        border-radius: 8px 8px 0 0;
        background-color: $white;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -1px;
        padding: 5px;
        margin: 0 auto;
        @include media_query(L) {
          height: 34px;
          width: 95px;
        }
        img {
          height: 22px;
          width: 22px;
          margin-right: 5px;
          @include media_query(L) {
            height: 24px;
            width: 24px;
            margin-right: 10px;
          }
        }
        .nav_text {
          font-size: 14px;
          font-weight: 400;
          @include media_query(L) {
            font-size: 16px;
          }
          @include media_query(XL) {
            font-size: 18px;
          }
        }
      }
    }
    .home_config_area {
      padding: 15px;
      @include media_query(XL) {
        padding: 15px 30px;;
      }
      .input_lbl {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 600;
      }
      .input_box {
        position: relative;
        margin-bottom: 15px;
        .rw-widget-input, .form-input {
          height: 36px;
          width: 100%;
          border: 1px solid $blue;
          border-radius: 8px;
          padding: 5px 0px 5px 30px;
          box-sizing: border-box;
          .rw-dropdown-list-input {
            padding: 0;
            line-height: 26px;
          }
        }
        .input_icon {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 12px;
          height: 16px;
          margin: auto;
          margin-left: 10px;
        }
      }
      .description {
        margin-bottom: 35px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: $blue;
        .para_one {
          margin-bottom: 20px;
        }
      }
    }
    .btn_area {
      display: flex;
      justify-content: space-between;
      padding: 0px 15px;
      @include media_query(XL) {
        padding: 0px 30px;
      }
      .back_btn {
        font-size: 12px;
        font-weight: 600;
        color: $dragon_green_1;
        display: flex;
        align-items: center;
        .left_arrow {
          height: 4px;
          width: 8px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .back_txt {
          line-height: normal;
          padding-top: 2px;
          margin-left: 6px;
          cursor: pointer;
        }
      }
      .visava-btn {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
        border-radius: 6px;
        background-color: $dragon_green_1;
        width: 135px;
        height: 40px; 
        @include media_query(XL) {
          width: 170px;
          height: 54px;
          font-size: 18px;
        }
        @include media_query(XXL) {
          width: 230px;
        }
      }
    }
    .switch_btn {
      display: flex;
      width: 125px;
      height: 28px;
      margin-bottom: 10px;
      border-radius: 8px;
      border: 1px solid $border_color;
      .feet {
        border-right: 1px solid $border_color;
        &.active {
          border-radius: 7px 0 0 7px;
          border-color: transparent;
        }
      }
      .feet, .metres {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 100%;
        color: $border_color;
        cursor: pointer;
        box-sizing: border-box;
        &.active {
          background-color: $dragon_green;
          color: $white;
          font-weight: 600;
          font-size: 12px;
        }
      }
    }
    .link {
      cursor: pointer;
      color: $dragon_green;
    }
  }
// Ends