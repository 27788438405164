.you-tube-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(53, 52, 52, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  overflow-x: hidden;
  overflow-y: auto;

  .you-tube-inner-container {
    width: auto;
    height: auto;
    background-color: rgba(255, 255, 255, 0.014);
  }
}
