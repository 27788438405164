.modal {
  background: $white;
  border-radius: 6px;

  .modal-content {
    background-color: $white;

    .modal-header {
      font-weight: 500;
      font-size: 21px;
      line-height: 21px;
      text-align: center;
      margin-bottom: 15px;
      color: $dragon_green;
    }

    .modal-body {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      color: $blue;
    }
  }
}

.box {
  position: relative;
  height: auto;
  max-height: 86vh;
  background: $white;
  border-radius: 4px;
  padding: 15px;
  border: 1px solid #999;
  overflow: auto;
  z-index: 18;
  margin: 0px;

  .close-icon {
    content: "x";
    cursor: pointer;
    position: fixed;
    right: calc(15% - 35px);
    top: calc(100vh - 85vh - 27px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-body {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: $blue;

    .paragraph {
      margin-bottom: 15px;
    }

    .paragraph-main {
      margin-bottom: 25px;
    }

    .paragraph-subpara {
      margin-bottom: 10px;
    }

    .modal-header {
      font-weight: 500;
      margin-top: 35px;
      font-size: 21px;
      line-height: 21px;
      text-align: center;
      margin-bottom: 15px;
      color: $dragon_green;
    }

    .highlight {
      font-weight: 1000;
    }
  }
}

.tnc-container {
  position: fixed;
  top: 65px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(53, 52, 52, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  overflow-x: hidden;
  overflow-y: auto;
  //height: calc(100vh - 125px);
  padding: 10px;

  @include media_query(L){
    top: 104px;
    padding: 30px;
    //height: calc(100vh - 164px);
  }
  .tnc-inner-container {
    width: 95%;
    max-height: 80vh;
    overflow: auto;
    color: black;
    position: relative;
    background: #fff;
    border-radius: 6px;
    padding-bottom: 30px;
    border: 1px solid #999;
    z-index: 18;
    margin: 0px;
    box-sizing: border-box;
    @include media_query(M) {
      width: 90%;
      max-height: 70vh;
    }
    @include media_query(L){
      margin: 0;
      width: 70%;
    }
    .close_icon_wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      padding: 20px 20px 10px;
      background-color: $white;
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
      .close_icon {
        background-image: url("../../../asset/images/close_icon_black.png");
        height: 30px;
        width: 30px;
        background-repeat: no-repeat;
        background-size: contain;          
        cursor: pointer;
      }
    }
    .modal-body {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: $blue;
      padding: 0 30px 30px;
      @include media_query(L){
        font-size: 16px;
        line-height: 22px;
        padding: 0 60px 30px;
      }
      ol {
        li {
          margin-left: 20px;
          margin-bottom: 15px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .paragraph {
        margin-bottom: 15px;
      }
  
      .paragraph-main {
        margin-bottom: 25px;
      }
  
      .paragraph-subpara {
        margin-bottom: 10px;
      }
      .bold {
        font-weight: 700;
      }
      .title {
        margin-bottom: 8px;
      }
      .modal-header {
        font-weight: 500;
        margin-top: 15px;
        font-size: 21px;
        line-height: 21px;
        text-align: center;
        margin-bottom: 15px;
        color: $dragon_green;
        @include media_query(XXL){
          margin-bottom: 50px;
        }
      }
  
      .highlight {
        font-weight: 700;
      }
    }
  }
  &.footer_privacy_policy {
    height: 100vh;
    top: 0;
    padding: 0;
  }
}
