/* Reset */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}



/* Default Classes */

body {
  height: 100%;
  margin: auto;
  font-family: 'Metropolis' !important;
  font-weight: 300;
  line-height: 1.3;
  font-size: 15px;
}

a {
  cursor: pointer;
  color: #000;
}

a:hover {
  color: #000;
}

img {
  max-width: 100%;
}

table {
  width: 100%;
}

button,
input,
optgroup,
select,
textarea {
  font-family: "Metropolis";
}

.clearfix {
  content: "";
  clear: both;
  display: block;
}

.icon {
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
}

.error-lbl {
  position: absolute;
  padding-top: 5px;
  font-size: 12px;
  font-weight: 300;
  color: #e53131;
}

.pos-relative {
  position: relative;
}

.pos-absolute {
  position: absolute;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.align-middle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.display-tbl {
  display: table;
  width: 100%;
  height: 100%;
}

.display-tbl-cell {
  display: table-cell;
  vertical-align: middle;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 17;
  padding: 0;
}

.inline-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 17;
}

.mask {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  // background-color: rgba(0, 0, 0, 0.7);
  // opacity: 0.9;
  filter: blur(2px);
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  // filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='svgMask'><feGaussianBlur stdDeviation='2' /></filter></svg>#svgMask");
}

.show {
  display: block;
}

.hide {
  display: none;
}
