@import '../../../asset/styles/scss/common/colors';
@import '../../../asset/styles/scss/common/media-queries';
.error_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $white;
  padding: 44px;
  color: $blue;
  height: 100vh;
  width: 100vw;
  position: relative;
  .logo_wrapper {
    display: block;
    position: absolute;
    top: 10px;
    left: 20px;
    @include media_query(L) {
      display: block;
    }
  }
  .inner_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $white;
    max-width: 700px;
    .error_img {
      width: 70px;
      height: 70px;
      margin-bottom: 16px;
      @include media_query(L) {
        width: 150px;
        height: 150px;
      }
    }
    .error_title {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 12px;
      text-align: center;
      @include media_query(L) {
        font-size: 33px;
        line-height: 44px;
      }
    }
    .error_sub_title {
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 24px;
      text-align: center;
      @include media_query(L) {
        font-size: 24px;
        line-height: 34px;
      }
    }
  }
}