.welcome-container {
  height: 100vh;
  @include media_query(L) {
    height: auto;
  }
  .welcome_img_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60%;
    background: linear-gradient(0.04deg, #00b3a9 -24.39%, #00c6bb 100.13%);
    padding: 30px 30px 60px 30px;
    @include media_query(L) {
      padding: 40px; 
      margin-bottom: 30px;
      height: auto;
    }
    .info_container {
      max-width: 660px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .img-area {
      img {
        margin-bottom: 30px;
        @include media_query(L) {
          margin-bottom: 40px;
        }
      }
    }  
      .welcome_text {
        color: $white;
        font-size: 14px;
        text-align: center;
        //text-transform: uppercase;
        .title {
          font-size: 18px;
          line-height: 22px;
          margin-bottom: 10px;
          font-weight: 500;
          @include media_query(L) {
            font-size: 28px;
            line-height: 32px;
          }
          @include media_query(XL) {
            font-size: 38px;
            line-height: 44px;
          }
          @include media_query(XXL) {
            font-size: 54px;
            line-height: 66px;
            margin-bottom: 20px;
          }
        }
        .sub_title {
          font-size: 14px;
          line-height: 18px;
          font-weight: 500;
          margin-bottom: 14px;
          @include media_query(L) {
            font-size: 16px;
            line-height: 20px;
          }
          @include media_query(XL) {
            font-size: 20px;
            line-height: 24px;
          }
          @include media_query(XXL) {
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 18px;
          }
        }
        .txt {
          font-size: 14px;
          line-height: 22px;
          @include media_query(L) {
            font-size: 16px;
            line-height: 20px;
          }
          @include media_query(XL) {
            font-size: 20px;
            line-height: 24px;
          }
          @include media_query(XXL) {
            font-size: 24px;
            line-height: 32px;
          }
        }
        @include media_query(XL) {
          font-size: 20px;
        }
        @include media_query(XXL) {
          font-size: 24px;
        }
      }
  }

  .simple-steps-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;

    .visava-logo-wrapper {
      background-color: $white;
      border-radius: 50%;
      position: relative;
      margin-top: -35px;
      padding: 10px;
      width: 95px;
      height: 95px;
      margin-bottom: 10px;

      @include media_query(L) {
        width: 90px;
        height: 80px;
        margin-top: 0;
        border-radius: 0px;
        padding: 0px;
        margin-bottom: 20px;
      }

      @include media_query(XXL) {
        width: 114px;
        height: 91px;
      }

      .visava-logo {
        height: 100%;
        width: 100%;
      }
    }

    .simple-steps-wrapper {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: $blue;
      width: 152px;
      margin-bottom: 15px;
      @include media_query(M) {
        width: auto;
      }
      @include media_query(L) {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.06em;
        width: auto;
      }
      @include media_query(XL) {
        font-size: 24px;
        line-height: 29px;
        width: auto;
      }
    }
  }

  .get-started-contaier {
    display: flex;
    justify-content: center;
    background: $dragon_green_1;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    height: 40px; 
    width: 233px;
    font-weight: 600;

    @include media_query(L) {
      width: 272px;
      height: 56px;
      box-shadow: none;
    }

    .get-started-wrapper {
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      line-height: 18x;

      @include media_query(L) {
        font-weight: 600;
        font-size: 20px;
      }
    }
  }
}
