/** Metropolis **/

$font-family-primary: "Metropolis";
$font-size-base: 15px !default;

/* Metropolis-Light */
@font-face {
  font-family: 'Metropolis';
  src: url(../../../styles/fonts/Metropolis-Light/Metropolis-Light.eot);
  src: url(../../../styles/fonts/Metropolis-Light/Metropolis-Light.woff2) format('woff2'),
    url(../../../styles/fonts/Metropolis-Light/Metropolis-Light.woff) format('woff'),
    url(../../../styles/fonts/Metropolis-Light/Metropolis-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

/* Metropolis-Regular */
@font-face {
  font-family: 'Metropolis';
  src: url(../../../styles/fonts/Metropolis-Regular/Metropolis-Regular.eot);
  src: url(../../../styles/fonts/Metropolis-Regular/Metropolis-Regular.woff2) format('woff2'),
    url(../../../styles/fonts/Metropolis-Regular/Metropolis-Regular.woff) format('woff'),
    url(../../../styles/fonts/Metropolis-Regular/Metropolis-Regular.ttf) format('truetype');
  font-style: normal;
  font-weight: 400;
}

/* Metropolis-Medium */
@font-face {
  font-family: 'Metropolis';
  src: url(../../../styles/fonts/Metropolis-Medium/Metropolis-Medium.eot);
  src: url(../../../styles/fonts/Metropolis-Medium/Metropolis-Medium.woff2) format('woff2'),
    url(../../../styles/fonts/Metropolis-Medium/Metropolis-Medium.woff) format('woff'),
    url(../../../styles/fonts/Metropolis-Medium/Metropolis-Medium.ttf) format('truetype');
  font-style: normal;
  font-weight: 500;
}

/* Metropolis-SemiBold */
@font-face {
  font-family: 'Metropolis';
  src: url(../../../styles/fonts/Metropolis-SemiBold/Metropolis-SemiBold.eot);
  src: url(../../../styles/fonts/Metropolis-SemiBold/Metropolis-SemiBold.woff2) format('woff2'),
    url(../../../styles/fonts/Metropolis-SemiBold/Metropolis-SemiBold.woff) format('woff'),
    url(../../../styles/fonts/Metropolis-SemiBold/Metropolis-SemiBold.ttf) format('truetype');
  font-style: normal;
  font-weight: 600;
}

/* Metropolis-Bold */
@font-face {
  font-family: 'Metropolis';
  src: url(../../../styles/fonts/Metropolis-Bold/Metropolis-Bold.eot);
  src: url(../../../styles/fonts/Metropolis-Bold/Metropolis-Bold.woff2) format('woff2'),
    url(../../../styles/fonts/Metropolis-Bold/Metropolis-Bold.woff) format('woff'),
    url(../../../styles/fonts/Metropolis-Bold/Metropolis-Bold.ttf) format('truetype');
  font-style: normal;
  font-weight: 700;
}
/* Martel-Light */
@font-face {
  font-family: 'Martel-light';
  src: url(../../../styles/fonts/martel/martel-light.otf);
  font-style: normal;
  font-weight: 300;
}
/* Martel-Regular */
@font-face {
  font-family: 'Martel';
  src: url(../../../styles/fonts/martel/martel-regular.otf);
  font-style: normal;
  font-weight: 400;
}
/* Martel-Medium */
@font-face {
  font-family: 'Martel-medium';
  src: url(../../../styles/fonts/martel/martel-demibold.otf);
  font-style: normal;
  font-weight: 600;
}
/* Martel-Bold*/
@font-face {
  font-family: 'Martel-bold';
  src: url(../../../styles/fonts/martel/martel-bold.otf);
  font-style: normal;
  font-weight: 700;
}