.our_story_main_container {
  box-sizing: border-box;
  .hero_banner_section {
    width: 100%;
    background-color: $light_cream_2;
    margin-bottom: 20px;
    img {
      width: 100%;
    }
    @include media_query(L) {
      margin-bottom: 50px;
    }
  }
  .section_padding {
    padding: 0 20px;
    margin-bottom: 20px;
    @include media_query(L) {
      padding: 0 80px;
      margin-bottom: 40px;
    }
    @include media_query(XXL) {
      padding: 0 170px;
      margin-bottom: 60px;
    }
  }
  .our_story_content {
    .content {
      font-weight: 400;
      font-size: 16px;
      line-height: 145.4%;
      color: $blue;
      text-align: justify;
      margin-bottom: 24px;
      @include media_query(L) {
        font-size: 18px;
        margin-bottom: 36px;
      }
      @include media_query(XL) {
        font-size: 21px;
      }
      @include media_query(XXL) {
        font-size: 24px;
      }
    }
  }
  .photo_gallery_mobile {
    background-color: $light_cream_2;
    padding-top: 20px;
    padding-bottom: 20px;
    @include media_query(L) {
      padding-top: 53px;
      padding-bottom: 53px;
      display: none;
    }
    .title {
      padding: 20px 0;
      font-weight: 500;
      font-size: 18px;
      line-height: 165.4%;
      color: $blue;
      .colored {
        color: $dragon_green;
      }
      @include media_query(M) {
        padding: 10px 20px 20px 0 ;
      }
      @include media_query(L) {
        padding: 10px 20px 20px;
        font-size: 24px;
      }
      @include media_query(XXL) {
        padding: 48px 20px 60px;
      }
    }
    .photo {
      margin-top: -10px;
    }
  }
  .photo_gallery_desktop {
    background-color: $light_cream_2;
    padding-top: 20px;
    padding-bottom: 20px;
    display: none;
    @include media_query(L) {
      padding-top: 53px;
      padding-bottom: 53px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .photo {
      position: relative;
      min-height: 200px;
    }
    .title {
      padding: 20px 0;
      font-weight: 500;
      font-size: 18px;
      line-height: 165.4%;
      color: $blue;
      position: absolute;
      left: 0;
      top: 0;
      .colored {
        color: $dragon_green;
      }
      @include media_query(L) {
        padding: 18px 0;
        max-width: 290px;
      }
      @include media_query(XL) {
        font-size: 24px;
        padding: 20px 0;
        max-width: 400px;
      }
      @include media_query(XXL) {
        padding: 48px 0;
        max-width: 520px;
      }
    }
  }
  .founder_section {
    .title {
      font-weight: 500;
      font-size: 33px;
      line-height: 133.4%;
      color: $blue;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;
    }
    .founder_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      margin-bottom: 70px;
      &:last-child {
        margin-bottom: 0;
      }
      @include media_query(XL) {
        flex-direction: row;
      }
      .founder_img {
        width: 200px;
        height: 200px;
        margin-bottom: 30px;
        img {
          width: 100%;
          height: 100%;
        }
        @include media_query(XL) {
          margin-bottom: 0;
        }
      }
      .founder_desc {
        font-weight: 400;
        font-size: 16px;
        line-height: 145.4%;
        text-align: justify;
        color: #192643;
        max-width: 100%;
        @include media_query(XL) {
          max-width: 600px;
          font-size: 18px;
        }
        @include media_query(XXL) {
          max-width: calc( 100% - 250px);
          font-size: 21px;
        }
      }
      &.revert {
        flex-direction: column;
        @include media_query(XL) {
          flex-direction: row-reverse;
        }
        
      }
    }
  }
  .team_section {
    position: relative;
    padding-top: 70px;
    padding-bottom: 10px;
    &::before,&::after {
      position: absolute;
      content:'';
      width: 200px;
      height: 1px;
      background-color: $black;
      z-index: 1;
      left:25%;
    }
    &::before { top: 0; }
    &::after { bottom: 0; }
    @include media_query(M) {
      &::before,&::after {
        width:400px;
      } 
    }
    @include media_query(XXL) {
      &::before,&::after {
        left: 32%;
        width:500px;
      } 
    }
    .title {
      font-weight: 500;
      font-size: 33px;
      line-height: 133.4%;
      color: $blue;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;
    }
    .team_member {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 20px;
      @include media_query(L) {
        margin-bottom: 58px;
      }
      .img {
        width: 200px;
        height: 200px;
        margin-bottom: 22px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .details {
        font-weight: 400;
        font-size: 21px;
        line-height: 145.4%;
        text-align: center;
        color: #192643;
      }
    }
  }
  .advisor_section {
    position: relative;
    .title {
      font-weight: 500;
      font-size: 33px;
      line-height: 133.4%;
      color: $blue;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;
    }
    .team_member {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 20px;
      .img {
        width: 200px;
        height: 200px;
        margin-bottom: 22px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .details {
        font-weight: 400;
        font-size: 21px;
        line-height: 145.4%;
        text-align: center;
        color: #192643;
      }
    }
  }
  .build-house-container {
    display: flex;
    justify-content: center;
    padding: 36px 20px;
    background-color: $light_cream_2;
    @include media_query(M) {
      padding: 36px 30px;
    }
    @include media_query(L) {
      padding: 36px 40px;
    }
    @include media_query(XL) {
      padding: 70px 80px;
    }
    .build-house-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .build-house-txt {
        font-weight: 500;
        font-size: 22px;
        line-height: 29px;
        display: flex;
        align-items: center;
        color: $blue;
        margin-bottom: 30px;
        letter-spacing: .2px;
  
        @include media_query(L) {
          font-size: 33px;
          line-height: 44px;
          margin-bottom: 44px;
        }
      }
      .btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @include media_query(L) {
          flex-direction: row;
        }
        .or-txt {
          font-weight: 500;
          font-size: 18px;
          line-height: 133.4%;
          color: $blue;
          margin-left: 10px;
          @include media_query(L) {
            margin: 0 27px 0 37px;
            font-size: 21px;
          }
        }
        .google-img {
          width: 185px;
          height: 68px;
          @include media_query(L) {
            width: 238px;
            height: 94px;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        height: 45px;
        width: 180px;
        background: $dragon_green_1;
        box-sizing: border-box;
        font-weight: 600;
        font-size: 14px;
        color: $white;
        cursor: pointer;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
        margin-bottom: 12px;
        @include media_query(L) {
          width: 272px;
          height: 64px;
          font-weight: 600;
          font-size: 24px;
          line-height: 133.4%;
          margin-bottom: 0;
        }
      }
    }
  }
}
