@import '../../../asset/styles/scss/common/colors';
@import '../../../asset/styles/scss/common/media-queries';
.overlay {
  z-index: 1102;
}
.details_popup_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 0 20px;
  .inner_wrapper {
    background-color: $white;
    border-radius: 15px;
    @include media_query(L) {
      width: 990px;
    }
  }
  .header {
    padding: 12px 21px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include media_query(L) {
      padding: 22px 18px;
    }
    .title {
      font-weight: 500;
      font-size: 21px;
      line-height: 21px;
      color: $dragon_green;
      @include media_query(L) {
        font-size: 33px;
        line-height: 33px;
      }
    }
  }
  .middle_table {
    .row {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid $blue;
      @include media_query(L) {
        flex-direction: row;
        &:first-child {
          border-top: 1px solid $blue;
        }
      }
      .label {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 12px;
        text-transform: uppercase;
        color: #666666;
        padding: 10px 21px 0;
        @include media_query(L) {
          font-size: 21px;
          line-height: 21px;
          color: $blue;
          text-transform: capitalize;
          width: 240px;
          border-right: 1px solid $blue;
          padding: 25px 21px;
        }
      }
      .txt {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: $blue;
        padding: 10px 21px;
        @include media_query(L) {
          font-size: 21px;
          line-height: 21px;
          font-weight: 400;
          width: calc(100% - 240px);
          padding: 13px 25px;
        }
        &.mobile_txt {
          display: flex;
          @include media_query(L) {
            display: none;
          }
        }
        &.desktop_txt {
          display: none;
          @include media_query(L) {
            display: flex;
          }
        }
      }
    }
  }
  .footer {
    padding: 22px 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    .btn {
      font-weight: 600;
      font-size: 12px;
      line-height: 13px;
      width: 233px;
      height: 42px;
      @include media_query(L) {
        font-size: 18px;
        line-height: 13px;
      }
    }
  }
}